import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import Login from './containers/Login';
import ManageUsers from './containers/ManageUsers';
import Agreements from './containers/Agreements';
import { UserProfile } from './containers/UserProfile';
import { CompanyProfile } from './containers/CompanyProfile';
import { ManageVendors } from './containers/ManageVendors';
import { StatsAndTrends } from './containers/StatsAndTrends';
import { ViewTools } from './containers/ViewTools';
import NotFound from './containers/NotFound';
import AuthenticatedRoute from './components/AuthenticatedRoute';
import { MyActivity } from './containers/MyActivity';
import { VerifyEmail } from './containers/VerifyEmail';
import { USER_TYPE } from './CONSTANTS';

export default ({ childProps }) => (
  <Routes>
    <Route path="*" element={<NotFound />} />
    <Route
      path="/"
      element={
        childProps.isAuthenticated ? (
          <Navigate to="/viewAgreements" />
        ) : (
          <Login {...childProps} />
        )
      }
    />
    <Route path="/login" element={<Login {...childProps} />} />
    <Route path="/verifyUserEmail" element={<VerifyEmail {...childProps} />} />
    <Route
      path="/myActivity"
      element={<AuthenticatedRoute component={MyActivity} props={childProps} />}
    />
    <Route
      path="/manageUsers"
      element={
        <AuthenticatedRoute
          component={ManageUsers}
          props={childProps}
          permissions={{
            roles: [
              USER_TYPE.SITE_ADMIN,
              USER_TYPE.OEM_ADMIN,
              USER_TYPE.TOOL_ADMIN,
            ],
          }}
        />
      }
    />
    <Route
      path="/viewAgreements"
      element={<AuthenticatedRoute component={Agreements} props={childProps} />}
    />
    <Route
      path="/manageVendors"
      element={
        <AuthenticatedRoute
          component={ManageVendors}
          props={childProps}
          permissions={{
            roles: [USER_TYPE.SITE_ADMIN],
          }}
        />
      }
    />
    <Route
      path="/viewTools"
      element={
        <AuthenticatedRoute
          component={ViewTools}
          props={childProps}
          permissions={{
            roles: [USER_TYPE.TOOL_ADMIN, USER_TYPE.TOOL_USER],
          }}
        />
      }
    />
    <Route
      path="/userProfile"
      element={
        <AuthenticatedRoute component={UserProfile} props={childProps} />
      }
    />
    <Route
      path="/statsAndTrends"
      element={
        <AuthenticatedRoute component={StatsAndTrends} props={childProps} />
      }
    />
    <Route
      path="/companyProfile"
      element={
        <AuthenticatedRoute component={CompanyProfile} props={childProps} />
      }
    />
  </Routes>
);
