import Dialog from '@reach/dialog';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { Typeahead } from 'react-bootstrap-typeahead';
import LoaderButton from '../../components/LoaderButton';
import { TextOnly, Text, LangDictKey } from '../../components/Text';
import { DOC_TYPE, REGIONS, REGIONS_LIST } from '../../CONSTANTS';
// // import { formatModelYears } from '../../libs/utils-ts';
import {
  AgreementDocument,
  // FormikFeeHistory,
  UpdateAgreement,
  Vendor,
} from '../../types';
import DatePicker from 'react-datepicker';
import MaskedInput from 'react-text-mask';
import { useConfirm } from '../../context/Confirm';
import { Loading } from '../../components/Loading';
import {
  getDocumentUrl,
  RemoveAgreementDocumentParams,
  adminUpdateAgreement,
  adminUploadDocumentUrl,
  adminRemoveDocument,
  UpdateAgreementStateParams,
  UploadAgreementDocumentUrlParams,
} from '../../libs/db-lib';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { UploadDoc } from '../../components/UploadDoc/UploadDoc';

interface EditAgreementSidebarProps {
  isOpen: boolean;
  isSite: boolean;
  toggleDialog: () => void;
  oemVendors: Vendor[];
  toolVendors: Vendor[];
  selectedAgreement: UpdateAgreement;
  setSelectedAgreement: (agreement: UpdateAgreement) => void;
  finishMutation: () => void;
}

interface AllDocsProps {
  licenseAgreementDoc?: AgreementDocument;
  comprehensiveFeesDoc?: AgreementDocument;
  otherFeesDoc?: AgreementDocument;
  insuranceDocsDoc?: AgreementDocument;
  supportDocsDoc?: AgreementDocument;
}

export const EditAgreementSidebar = ({
  isOpen,
  toggleDialog,
  isSite,
  oemVendors,
  toolVendors,
  selectedAgreement,
  setSelectedAgreement,
  finishMutation,
}: EditAgreementSidebarProps) => {
  const { handleSetConfirmProps } = useConfirm();
  // DO NOT DELETE: May need accordion in the future.
  // const [showAccordion, setShowAccordion] = useState(false);
  const [isLoadingLicenseAgreementDoc, setIsLoadingLicenseAgreementDoc] =
    useState(false);
  const [isLoadingComprehensiveFeesDoc, setIsLoadingComprehensiveFeesDoc] =
    useState(false);
  const [isLoadingOtherFeesDoc, setIsLoadingOtherFeesDoc] = useState(false);
  const [isLoadingSupportDocsDoc, setIsLoadingSupportDocsDoc] = useState(false);
  const [isLoadingInsuranceDocsDoc, setIsLoadingInsuranceDocsDoc] =
    useState(false);
  const [isLoadingAnnualFeesDoc, setIsLoadingAnnualFeesDoc] = useState(false);
  // DO NOT DELETE: May need accordion in the future.
  // const [isLoadingPreviousAnnualFeesDoc, setIsLoadingPreviousAnnualFeesDoc] =
  //   useState(false);
  // const [
  //   hasFetchedPreviousAnnualFeesDocs,
  //   setHasFetchedPreviousAnnualFeesDocs,
  // ] = useState(false);

  const dateRegexPattern = [
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    '/',
    /\d/,
    /\d/,
    '/',
    /\d/,
    /\d/,
  ];

  const year = new Date().getFullYear();
  const yearsList = Array.from(new Array(year - 2019 + 2), (v, i) =>
    (2019 + i).toString()
  );

  const siteAdminUpdateAgreementMutation = useMutation(
    (agreementFields: UpdateAgreementStateParams) =>
      adminUpdateAgreement(agreementFields),
    {
      onSuccess: (data: any) => {
        if (data?.error) {
          toast.error(data.error, {
            autoClose: false,
            containerId: 'standard',
          });
          return;
        }
        toast.success(TextOnly('updateAgreementSuccess'), {
          containerId: 'standard',
        });
        formik.resetForm();
        finishMutation();
      },
      onError: (error: Error) => {
        toast.error(error.message, {
          autoClose: false,
          containerId: 'standard',
        });
      },
    }
  );

  const siteAdminUploadDocumentMutation = useMutation(
    (documentFields: UploadAgreementDocumentUrlParams) =>
      adminUploadDocumentUrl(documentFields),
    {
      onSuccess: (data: any) => {
        if (data?.error) {
          console.log('upload error', data?.error);
          toast.error(data.error, {
            autoClose: false,
            containerId: 'standard',
          });
          return;
        }
      },
      onError: (error: Error) => {
        console.log('upload error', error);
        toast.error(error.message, {
          autoClose: false,
          containerId: 'standard',
        });
      },
    }
  );

  const siteAdminRemoveDocumentMutation = useMutation(
    (documentFields: RemoveAgreementDocumentParams) =>
      adminRemoveDocument(documentFields),
    {
      onSuccess: (data: any) => {
        if (data?.error) {
          console.log('remove error', data?.error);
          toast.error(data.error, {
            autoClose: false,
            containerId: 'standard',
          });
          return;
        }
        console.log('remove data', data);
        toast.success(TextOnly('removeDocumentSuccess'), {
          containerId: 'standard',
        });
        finishMutation();
      },
      onError: (error: Error) => {
        console.log('remove error', error);
        toast.error(error.message, {
          autoClose: false,
          containerId: 'standard',
        });
      },
    }
  );

  const formik = useFormik({
    initialValues: {
      agreementName: selectedAgreement?.agreementName,
      agreementRegion: selectedAgreement?.agreementRegion,
      agreementNotes: selectedAgreement?.agreementNotes,
      annualFees: {
        isApplicable: selectedAgreement?.annualFees.isApplicable || false,
        feeHistory:
          selectedAgreement?.annualFees?.feeHistory
            ?.sort((a, b) => {
              return b.modelYear < a.modelYear ? -1 : 1;
            })
            .map((fee) => ({
              invoiceDate: fee.invoiceDate || 'none',
              modelYear: fee.modelYear !== '' ? fee.modelYear?.split(',') : [],
              receivedDate: fee.receivedDate || 'none',
              paidDate: fee.paidDate || 'none',
              paidToOem: fee.paidToOem || 'none',
              adDocExists: fee.adDocExists || false,
              adDocName: fee.adDocName || '',
              document: fee?.annualFeesDoc || undefined,
            })) || [],
      },
      licenseAgreement: {
        receivedDate: selectedAgreement?.licenseAgreement.receivedDate,
        signedDate: selectedAgreement?.licenseAgreement.signedDate,
        sentDate: selectedAgreement?.licenseAgreement.sentDate,
        document: selectedAgreement?.licenseAgreementDoc || undefined,
        ldDocExists: selectedAgreement?.licenseAgreement.ldDocExists,
        ldDocName: selectedAgreement?.licenseAgreement.ldDocName,
      },
      comprehensiveFees: {
        isApplicable:
          selectedAgreement?.comprehensiveFees.isApplicable || false,
        invoiceDate: selectedAgreement?.comprehensiveFees.invoiceDate,
        paidDate: selectedAgreement?.comprehensiveFees.paidDate,
        paidToOem: selectedAgreement?.comprehensiveFees.paidToOem,
        receivedDate: selectedAgreement?.comprehensiveFees.receivedDate,
        document: selectedAgreement?.comprehensiveFeesDoc || undefined,
        cdDocExists: selectedAgreement?.comprehensiveFees.cdDocExists,
        cdDocName: selectedAgreement?.comprehensiveFees.cdDocName,
      },
      otherFees: {
        isApplicable: selectedAgreement?.otherFees.isApplicable || false,
        invoiceDate: selectedAgreement?.otherFees.invoiceDate,
        paidDate: selectedAgreement?.otherFees.paidDate,
        paidToOem: selectedAgreement?.otherFees.paidToOem,
        receivedDate: selectedAgreement?.otherFees.receivedDate,
        document: selectedAgreement?.otherFeesDoc || undefined,
        odDocExists: selectedAgreement?.otherFees.odDocExists,
        odDocName: selectedAgreement?.otherFees.odDocName,
      },
      insuranceDocs: {
        isApplicable: selectedAgreement?.insuranceDocs.isApplicable || false,
        receivedDate: selectedAgreement?.insuranceDocs.receivedDate,
        expirationDate: selectedAgreement?.insuranceDocs.expirationDate,
        effectiveDate: selectedAgreement?.insuranceDocs.effectiveDate,
        document: selectedAgreement?.insuranceDocsDoc || undefined,
        idDocExists: selectedAgreement?.insuranceDocs.idDocExists,
        idDocName: selectedAgreement?.insuranceDocs.idDocName,
      },
      supportDocs: {
        receivedDate: selectedAgreement?.supportDocs.receivedDate,
        isApplicable: selectedAgreement?.supportDocs.isApplicable || false,
        expirationDate: selectedAgreement?.supportDocs.expirationDate,
        document: selectedAgreement?.supportDocsDoc || undefined,
        sdDocExists: selectedAgreement?.supportDocs.sdDocExists,
        sdDocName: selectedAgreement?.supportDocs.sdDocName,
      },
    },
    onSubmit: async (values) => {
      if (values.licenseAgreement.document?.file) {
        await siteAdminUploadDocumentMutation.mutateAsync({
          roleType: 'SITE_ADMIN',
          agreementID: selectedAgreement?.agreementID as string,
          documentType: DOC_TYPE.LD,
          documentName: values.licenseAgreement.document.documentName,
          file: values.licenseAgreement.document.file,
        });
      }

      if (values.comprehensiveFees.document?.file) {
        await siteAdminUploadDocumentMutation.mutateAsync({
          roleType: 'SITE_ADMIN',
          agreementID: selectedAgreement?.agreementID as string,
          documentType: DOC_TYPE.CD,
          documentName: values.comprehensiveFees.document.documentName,
          file: values.comprehensiveFees.document.file,
        });
      }

      if (values.otherFees.document?.file) {
        await siteAdminUploadDocumentMutation.mutateAsync({
          roleType: 'SITE_ADMIN',
          agreementID: selectedAgreement?.agreementID as string,
          documentType: DOC_TYPE.OD,
          documentName: values.otherFees.document.documentName,
          file: values.otherFees.document.file,
        });
      }

      if (values.insuranceDocs.document?.file) {
        await siteAdminUploadDocumentMutation.mutateAsync({
          roleType: 'SITE_ADMIN',
          agreementID: selectedAgreement?.agreementID as string,
          documentType: DOC_TYPE.ID,
          documentName: values.insuranceDocs.document.documentName,
          file: values.insuranceDocs.document.file,
        });
      }

      if (values.supportDocs.document?.file) {
        await siteAdminUploadDocumentMutation.mutateAsync({
          roleType: 'SITE_ADMIN',
          agreementID: selectedAgreement?.agreementID as string,
          documentType: DOC_TYPE.SD,
          documentName: values.supportDocs.document.documentName,
          file: values.supportDocs.document.file,
        });
      }

      if (values.annualFees.feeHistory?.length) {
        values.annualFees.feeHistory.forEach(async (entry) => {
          if (entry.document && entry.document?.file) {
            await siteAdminUploadDocumentMutation.mutateAsync({
              roleType: 'SITE_ADMIN',
              agreementID: selectedAgreement?.agreementID as string,
              documentType: DOC_TYPE.AD,
              documentName: entry.document.documentName,
              file: entry.document.file,
              modelYear: entry.modelYear.join(','),
            });
          }
        });
      }

      siteAdminUpdateAgreementMutation.mutate({
        roleType: 'SITE_ADMIN',
        agreementID: selectedAgreement?.agreementID || '',
        oemVendorID: selectedAgreement?.oemVendorID || '',
        agreementName: values.agreementName || '',
        agreementRegion: values.agreementRegion || '',
        agreementNotes: values.agreementNotes || '',
        annualFees: {
          isApplicable: values.annualFees.isApplicable,
          feeHistory:
            values.annualFees?.feeHistory?.map((fee) => ({
              invoiceDate:
                fee.invoiceDate && fee.invoiceDate !== 'none'
                  ? moment(fee.invoiceDate).format()
                  : 'none',
              modelYear:
                fee.modelYear && fee.modelYear.length > 0
                  ? fee.modelYear.join(',')
                  : '',
              receivedDate:
                fee.receivedDate && fee.receivedDate !== 'none'
                  ? moment(fee.receivedDate).format()
                  : 'none',
              paidDate:
                fee.paidDate && fee.paidDate !== 'none'
                  ? moment(fee.paidDate).format()
                  : 'none',
              paidToOem:
                fee.paidToOem && fee.paidToOem !== 'none'
                  ? moment(fee.paidToOem).format()
                  : 'none',
            })) || [],
        },
        licenseAgreement: {
          signedDate:
            values?.licenseAgreement.signedDate &&
            values.licenseAgreement.signedDate !== 'none'
              ? moment(values.licenseAgreement.signedDate).format()
              : 'none',
          receivedDate:
            values?.licenseAgreement.receivedDate &&
            values.licenseAgreement.receivedDate !== 'none'
              ? moment(values.licenseAgreement.receivedDate).format()
              : 'none',
          sentDate:
            values?.licenseAgreement.sentDate &&
            values.licenseAgreement.sentDate !== 'none'
              ? moment(values.licenseAgreement.sentDate).format()
              : 'none',
        },
        insuranceDocs: {
          receivedDate:
            values?.insuranceDocs.receivedDate &&
            values.insuranceDocs.receivedDate !== 'none'
              ? moment(values.insuranceDocs.receivedDate).format()
              : 'none',
          isApplicable: values.insuranceDocs.isApplicable,
          expirationDate:
            values?.insuranceDocs.expirationDate &&
            values.insuranceDocs.expirationDate !== 'none'
              ? moment(values.insuranceDocs.expirationDate).format()
              : 'none',
          effectiveDate:
            values?.insuranceDocs.effectiveDate &&
            values.insuranceDocs.effectiveDate !== 'none'
              ? moment(values.insuranceDocs.effectiveDate).format()
              : 'none',
        },
        supportDocs: {
          receivedDate:
            values?.supportDocs.receivedDate &&
            values.supportDocs.receivedDate !== 'none'
              ? moment(values.supportDocs.receivedDate).format()
              : 'none',
          isApplicable: values.supportDocs.isApplicable,
          expirationDate:
            values?.supportDocs.expirationDate &&
            values.supportDocs.expirationDate !== 'none'
              ? moment(values.supportDocs.expirationDate).format()
              : 'none',
        },
        comprehensiveFees: {
          receivedDate:
            values?.comprehensiveFees.receivedDate &&
            values.comprehensiveFees.receivedDate !== 'none'
              ? moment(values.comprehensiveFees.receivedDate).format()
              : 'none',
          isApplicable: values.comprehensiveFees.isApplicable,
          invoiceDate:
            values?.comprehensiveFees.invoiceDate &&
            values.comprehensiveFees.invoiceDate !== 'none'
              ? moment(values.comprehensiveFees.invoiceDate).format()
              : 'none',
          paidDate:
            values?.comprehensiveFees.paidDate &&
            values.comprehensiveFees.paidDate !== 'none'
              ? moment(values.comprehensiveFees.paidDate).format()
              : 'none',
          paidToOem:
            values?.comprehensiveFees.paidToOem &&
            values.comprehensiveFees.paidToOem !== 'none'
              ? moment(values.comprehensiveFees.paidToOem).format()
              : 'none',
        },
        otherFees: {
          receivedDate:
            values?.otherFees.receivedDate &&
            values.otherFees.receivedDate !== 'none'
              ? moment(values.otherFees.receivedDate).format()
              : 'none',
          isApplicable: values.otherFees.isApplicable,
          invoiceDate:
            values?.otherFees.invoiceDate &&
            values.otherFees.invoiceDate !== 'none'
              ? moment(values.otherFees.invoiceDate).format()
              : 'none',
          paidDate:
            values?.otherFees.paidDate && values.otherFees.paidDate !== 'none'
              ? moment(values.otherFees.paidDate).format()
              : 'none',
          paidToOem:
            values?.otherFees.paidToOem && values.otherFees.paidToOem !== 'none'
              ? moment(values.otherFees.paidToOem).format()
              : 'none',
        },
      });
    },
    enableReinitialize: true,
  });

  const getDocumentData = async (documentRequestParams: {
    docType: DOC_TYPE;
    modelYear?: string;
  }) => {
    return await getDocumentUrl({
      agreementID: selectedAgreement?.agreementID as string,
      documentType: documentRequestParams.docType,
      modelYear: documentRequestParams.modelYear,
    })
      .then((data) => {
        return data;
      })
      .catch((error: any) => {
        toast.error(error, {
          autoClose: false,
          containerId: 'standard',
        });
        return undefined;
      });
  };

  // ** DO NOT DELETE: May need accordion in the future **

  // const openAnnualFeesAccordion = async () => {
  //   const annualFeeHistory = selectedAgreement.annualFees.feeHistory;

  //   annualFeeHistory.forEach(async (fee, index) => {
  //     setIsLoadingPreviousAnnualFeesDoc(true);
  //     if (index !== 0) {
  //       if (fee.adDocExists) {
  //         await getDocumentData({
  //           docType: DOC_TYPE.AD,
  //           modelYear: fee?.modelYear,
  //         }).then((data: AgreementDocument | undefined) => {
  //           if (data !== undefined) {
  //             formik.setFieldValue(
  //               `annualFees.feeHistory[${index}].document`,
  //               data
  //             );
  //             annualFeeHistory[index].annualFeesDoc = data;
  //             setSelectedAgreement({
  //               ...selectedAgreement,
  //               annualFees: {
  //                 ...selectedAgreement.annualFees,
  //                 feeHistory: annualFeeHistory,
  //               },
  //             });
  //           }
  //         });
  //       }
  //     }
  //     if (index === annualFeeHistory.length - 1) {
  //       setIsLoadingPreviousAnnualFeesDoc(false);
  //     }
  //   });

  //   setHasFetchedPreviousAnnualFeesDocs(true);
  // };

  // useEffect(() => {
  //   if (showAccordion && !hasFetchedPreviousAnnualFeesDocs) {
  //     openAnnualFeesAccordion();
  //   }
  // }, [showAccordion, hasFetchedPreviousAnnualFeesDocs]);

  // const toggleAccordion = () => {
  //   setShowAccordion(!showAccordion);
  // };

  const confirmDeleteDoc = (deleteProps: {
    selectedAgreementField: string;
    formikField: string;
    documentType: DOC_TYPE;
    modelYear?: string;
  }) => {
    handleSetConfirmProps({
      title: TextOnly('confirmDeleteDoc'),
      message: TextOnly('confirmDeleteDocMessage'),
      handleConfirm: () => {
        siteAdminRemoveDocumentMutation.mutate({
          roleType: 'SITE_ADMIN',
          agreementID: selectedAgreement?.agreementID as string,
          documentType: deleteProps.documentType,
          modelYear: deleteProps.modelYear,
        });
        formik.setFieldValue(deleteProps.formikField, undefined);

        setSelectedAgreement({
          ...selectedAgreement,
          [deleteProps.selectedAgreementField]: undefined,
        });
      },
    });
  };

  const downloadDocument = async (docType: DOC_TYPE, modelYear?: string) => {
    let docName = '';
    await getDocumentUrl({
      agreementID: selectedAgreement?.agreementID as string,
      documentType: docType,
      modelYear: modelYear,
    })
      .then(async (doc: AgreementDocument) => {
        docName = doc.documentName;
        if (doc.documentUrl) {
          return await fetch(doc.documentUrl);
        } else {
          return new Error('No documentUrl');
        }
      })
      .then(async (response: any) => {
        const blob = await response.blob();

        const file = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.style.display = 'none';
        document.body.appendChild(link);
        link.href = file;
        link.setAttribute('download', `${docName}`);
        link.click();

        window.URL.revokeObjectURL(link.href);
        document.body.removeChild(link);
      })
      .catch((error) => {
        handleSetConfirmProps({
          title: 'Error',
          message: error?.error,
        });
      });
  };

  const handleAddNewYear = async () => {
    if (formik.values.annualFees.feeHistory[0].document?.file) {
      await siteAdminUploadDocumentMutation.mutateAsync({
        roleType: 'SITE_ADMIN',
        agreementID: selectedAgreement?.agreementID as string,
        documentType: DOC_TYPE.AD,
        documentName:
          formik.values.annualFees.feeHistory[0].document.documentName,
        file: formik.values.annualFees.feeHistory[0].document.file,
        modelYear: formik.values.annualFees.feeHistory[0].modelYear.join(','),
      });
    }

    formik.setFieldValue('annualFees.feeHistory', [
      {
        modelYear: [],
        invoiceDate: 'none',
        receivedDate: 'none',
        paidDate: 'none',
        paidToOem: 'none',
        document: undefined,
      },
      ...formik.values.annualFees.feeHistory,
    ]);

    setSelectedAgreement({
      ...selectedAgreement,
      annualFees: {
        ...selectedAgreement.annualFees,
        feeHistory: [
          {
            modelYear: '',
            invoiceDate: 'none',
            receivedDate: 'none',
            paidDate: 'none',
            paidToOem: 'none',
            adDocExists: false,
            annualFeesDoc: undefined,
          },
          ...selectedAgreement.annualFees.feeHistory,
        ],
      },
    });
  };

  if (selectedAgreement === null) {
    return <Loading />;
  }

  return (
    <Dialog
      isOpen={isOpen}
      onDismiss={toggleDialog}
      className="c-modal-slider c-modal-slider--wide"
      aria-label={TextOnly('editAgreement')}
    >
      <button
        className="c-btn-icon c-modal-slider__close"
        onClick={toggleDialog}
      >
        <div className="c-btn__inner">
          <i className="c-btn__icon fa fa-times" />
        </div>
      </button>
      <h1 className="c-modal__heading">
        <Text tid="editAgreement" />
      </h1>

      <div className="c-modal__body">
        <form onSubmit={formik.handleSubmit}>
          <div className="">
            <div className="c-agreement-box__grid-layout-header">
              <div className="c-agreement-box__select">
                <p className="c-agreement-box__title">
                  <Text tid="oemMfr" />
                </p>
                <Typeahead
                  id="userTypeFilter"
                  className="c-agreement-box__typeahead"
                  placeholder="Select OEM..."
                  disabled
                  selected={[
                    {
                      label:
                        oemVendors?.find(
                          (oem) =>
                            oem.vendorID ===
                            (selectedAgreement?.oemVendorID as string)
                        )?.vendorName || 'Oem Mfr',
                      value: selectedAgreement?.oemVendorID,
                    },
                  ]}
                  options={oemVendors?.map((oem) => ({
                    label: oem.vendorName,
                    value: oem.vendorID,
                  }))}
                  onChange={(selected) => {
                    if (selected.length > 0) {
                      formik.setFieldValue('oemVendorID', selected?.[0]?.value);
                    }
                  }}
                  positionFixed
                />
              </div>
              <div className="c-agreement-box__select">
                <p className="c-agreement-box__title">
                  <Text tid="toolMfr" />
                </p>
                <Typeahead
                  id="userTypeFilter"
                  className="c-agreement-box__typeahead"
                  placeholder="Select tool mfr..."
                  disabled
                  selected={[
                    {
                      label:
                        toolVendors?.find(
                          (tool) =>
                            tool.vendorID ===
                            (selectedAgreement?.toolVendorID as string)
                        )?.vendorName || 'Tool Mfr',
                      value: selectedAgreement?.toolVendorID,
                    },
                  ]}
                  options={toolVendors?.map((tool) => ({
                    label: tool.vendorName,
                    value: tool.vendorID,
                  }))}
                  onChange={(selected) => {
                    if (selected.length > 0) {
                      formik.setFieldValue(
                        'toolVendorID',
                        selected?.[0]?.value
                      );
                    }
                  }}
                  positionFixed
                />
              </div>
              <div className="c-agreement-box__select">
                <p className="c-agreement-box__title">
                  <Text tid="region" />
                </p>
                <Typeahead
                  id="userTypeFilter"
                  className="c-agreement-box__typeahead"
                  placeholder="Select region..."
                  disabled={!isSite}
                  options={REGIONS_LIST.map((region) => ({
                    label: TextOnly(region as LangDictKey),
                    value: region,
                  }))}
                  selected={
                    formik.values.agreementRegion
                      ? [
                          {
                            label: TextOnly(
                              formik.values.agreementRegion as REGIONS
                            ),
                            value: formik.values.agreementRegion,
                          },
                        ]
                      : []
                  }
                  onChange={(selected) => {
                    if (selected.length > 0) {
                      formik.setFieldValue(
                        'agreementRegion',
                        selected?.[0].value
                      );
                    }
                  }}
                  positionFixed
                />
              </div>
              <div />
              <div>
                <p className="c-agreement-box__title">
                  <Text tid="created" />
                </p>
                <p>
                  {moment(selectedAgreement?.createdOn).format('YYYY-MM-DD')}
                </p>
              </div>
            </div>
          </div>

          {/* LICENSE AGREEMENT */}

          <div className="c-agreement-box">
            <div className="c-agreement-box__grid-layout-license">
              <div className="c-agreement-box__align-top">
                <p className="c-agreement-box__title">
                  <Text tid="licenseAgreement" />
                </p>
              </div>
              <div className="c-agreement-box__date">
                <label htmlFor="active">
                  <Text tid="received" />
                </label>
                <div className="c-choose-date">
                  <DatePicker
                    placeholderText="choose date..."
                    className="c-date-picker"
                    disabled={!isSite}
                    dateFormat="yyyy/MM/dd"
                    selected={
                      formik.values.licenseAgreement.receivedDate !== 'none'
                        ? new Date(formik.values.licenseAgreement.receivedDate)
                        : null
                    }
                    customInput={
                      <MaskedInput
                        mask={dateRegexPattern}
                        keepCharPositions={true}
                        guide={true}
                      />
                    }
                    onChange={(date) => {
                      formik.setFieldValue(
                        'licenseAgreement.receivedDate',
                        date === null ? 'none' : date
                      );
                    }}
                  />
                </div>
              </div>
              <div className="c-agreement-box__date">
                <label htmlFor="active">
                  <Text tid="sent" />
                </label>
                <div className="c-choose-date">
                  <DatePicker
                    placeholderText="choose date..."
                    className="c-date-picker"
                    disabled={!isSite}
                    dateFormat="yyyy/MM/dd"
                    selected={
                      formik.values.licenseAgreement.sentDate !== 'none'
                        ? new Date(formik.values.licenseAgreement.sentDate)
                        : null
                    }
                    customInput={
                      <MaskedInput
                        mask={dateRegexPattern}
                        keepCharPositions={true}
                        guide={true}
                      />
                    }
                    onChange={(date) => {
                      formik.setFieldValue(
                        'licenseAgreement.sentDate',
                        date === null ? 'none' : date
                      );
                    }}
                  />
                </div>
              </div>
              <div className="c-agreement-box__date">
                <label htmlFor="active">
                  <Text tid="signed" />
                </label>
                <div className="c-choose-date">
                  <DatePicker
                    placeholderText="choose date..."
                    className="c-date-picker"
                    disabled={!isSite}
                    dateFormat="yyyy/MM/dd"
                    selected={
                      formik.values.licenseAgreement.signedDate !== 'none'
                        ? new Date(formik.values.licenseAgreement.signedDate)
                        : null
                    }
                    customInput={
                      <MaskedInput
                        mask={dateRegexPattern}
                        keepCharPositions={true}
                        guide={true}
                      />
                    }
                    onChange={(date) => {
                      formik.setFieldValue(
                        'licenseAgreement.signedDate',
                        date === null ? 'none' : date
                      );
                    }}
                  />
                </div>
              </div>
              <div className="c-agreement-box__align-bottom l-flex-between c-agreement-box__doc-box">
                {formik.values.licenseAgreement.ldDocExists ||
                formik.values.licenseAgreement.document ? (
                  <>
                    <button
                      className="c-btn-link-text c-agreement-box__doc-link"
                      type="button"
                      onClick={() => downloadDocument(DOC_TYPE.LD)}
                    >
                      {formik.values.licenseAgreement.ldDocName ??
                        formik.values.licenseAgreement.document?.documentName}
                    </button>
                    <button
                      className="c-btn-icon"
                      type="button"
                      disabled={!isSite}
                      onClick={() => {
                        const formikField = formik.values.licenseAgreement
                          .document
                          ? 'licenseAgreement.document'
                          : 'licenseAgreement.ldDocExists';
                        confirmDeleteDoc({
                          selectedAgreementField: 'licenseAgreementDoc',
                          formikField: formikField,
                          documentType: DOC_TYPE.LD,
                        });
                      }}
                    >
                      <i className="fal fa-trash-alt c-agreement-box__delete-btn"></i>
                    </button>
                  </>
                ) : (
                  <UploadDoc
                    handleSetFile={({
                      documentName,
                      file,
                    }: {
                      documentName: string;
                      file: any;
                    }) => {
                      formik.setFieldValue('licenseAgreement.document', {
                        documentName,
                        file,
                      });
                    }}
                  />
                )}
              </div>
            </div>
            <div className="c-agreement-box__textbox-grid-layout">
              <textarea
                className="c-textarea"
                disabled={!isSite}
                placeholder="Enter text here..."
                name="agreementNotes"
                rows={3}
                value={formik.values.agreementNotes}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </div>
          </div>

          {/* COMP FEES */}

          <div className="c-agreement-box">
            <div className="c-agreement-box__grid-layout">
              <div className="c-agreement-box__align-top">
                <p className="c-agreement-box__title">
                  <Text tid="compFees" />
                </p>
                <div className="l-flex-start">
                  <input
                    type="checkbox"
                    disabled={!isSite}
                    className="c-agreement-box__applicable-checkbox"
                    name="comprehensiveFees.isApplicable"
                    value={formik.values.comprehensiveFees.isApplicable.toString()}
                    checked={formik.values.comprehensiveFees.isApplicable}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  <p className="c-agreement-box__applicable-text">
                    <Text tid="applicable" />
                  </p>
                </div>
              </div>

              <div className="c-agreement-box__date">
                <label
                  htmlFor="active"
                  className={`${
                    !formik.values.comprehensiveFees.isApplicable
                      ? 'c-choose-date--disabled-label'
                      : ''
                  }`}
                >
                  <Text tid="invoiced" />
                </label>
                <div className="c-choose-date">
                  <DatePicker
                    placeholderText="choose date..."
                    className="c-date-picker"
                    dateFormat="yyyy/MM/dd"
                    disabled={
                      !formik.values.comprehensiveFees.isApplicable || !isSite
                    }
                    selected={
                      formik.values.comprehensiveFees.invoiceDate !== 'none'
                        ? new Date(formik.values.comprehensiveFees.invoiceDate)
                        : null
                    }
                    customInput={
                      <MaskedInput
                        mask={dateRegexPattern}
                        keepCharPositions={true}
                        guide={true}
                      />
                    }
                    onChange={(date) => {
                      formik.setFieldValue(
                        'comprehensiveFees.invoiceDate',
                        date === null ? 'none' : date
                      );
                    }}
                  />
                </div>
              </div>
              <div className="c-agreement-box__date">
                <label
                  htmlFor="active"
                  className={`${
                    !formik.values.comprehensiveFees.isApplicable
                      ? 'c-choose-date--disabled-label'
                      : ''
                  }`}
                >
                  <Text tid="poRecResent" />
                </label>
                <div className="c-choose-date">
                  <DatePicker
                    placeholderText="choose date..."
                    className="c-date-picker"
                    dateFormat="yyyy/MM/dd"
                    disabled={
                      !formik.values.comprehensiveFees.isApplicable || !isSite
                    }
                    selected={
                      formik.values.comprehensiveFees.receivedDate !== 'none'
                        ? new Date(formik.values.comprehensiveFees.receivedDate)
                        : null
                    }
                    customInput={
                      <MaskedInput
                        mask={dateRegexPattern}
                        keepCharPositions={true}
                        guide={true}
                      />
                    }
                    onChange={(date) => {
                      formik.setFieldValue(
                        'comprehensiveFees.receivedDate',
                        date === null ? 'none' : date
                      );
                    }}
                  />
                </div>
              </div>
              <div className="c-agreement-box__date">
                <label
                  htmlFor="active"
                  className={`${
                    !formik.values.comprehensiveFees.isApplicable
                      ? 'c-choose-date--disabled-label'
                      : ''
                  }`}
                >
                  <Text tid="paidPending" />
                </label>
                <div className="c-choose-date">
                  <DatePicker
                    placeholderText="choose date..."
                    className="c-date-picker"
                    dateFormat="yyyy/MM/dd"
                    disabled={
                      !formik.values.comprehensiveFees.isApplicable || !isSite
                    }
                    selected={
                      formik.values.comprehensiveFees.paidDate !== 'none'
                        ? new Date(formik.values.comprehensiveFees.paidDate)
                        : null
                    }
                    customInput={
                      <MaskedInput
                        mask={dateRegexPattern}
                        keepCharPositions={true}
                        guide={true}
                      />
                    }
                    onChange={(date) => {
                      formik.setFieldValue(
                        'comprehensiveFees.paidDate',
                        date === null ? 'none' : date
                      );
                    }}
                  />
                </div>
              </div>
              <div className="c-agreement-box__date">
                <label
                  htmlFor="active"
                  className={`${
                    !formik.values.comprehensiveFees.isApplicable
                      ? 'c-choose-date--disabled-label'
                      : ''
                  }`}
                >
                  <Text tid="paidToOem" />
                </label>
                <div className="c-choose-date">
                  <DatePicker
                    placeholderText="choose date..."
                    className="c-date-picker"
                    dateFormat="yyyy/MM/dd"
                    disabled={
                      !formik.values.comprehensiveFees.isApplicable || !isSite
                    }
                    selected={
                      formik.values.comprehensiveFees.paidToOem !== 'none'
                        ? new Date(formik.values.comprehensiveFees.paidToOem)
                        : null
                    }
                    customInput={
                      <MaskedInput
                        mask={dateRegexPattern}
                        keepCharPositions={true}
                        guide={true}
                      />
                    }
                    onChange={(date) => {
                      formik.setFieldValue(
                        'comprehensiveFees.paidToOem',
                        date === null ? 'none' : date
                      );
                    }}
                  />
                </div>
              </div>
              <div className="c-agreement-box__align-bottom l-flex-between c-agreement-box__doc-box">
                {formik.values.comprehensiveFees.cdDocExists ||
                formik.values.comprehensiveFees.document ? (
                  <>
                    <button
                      className="c-btn-link-text c-agreement-box__doc-link"
                      type="button"
                      onClick={() => downloadDocument(DOC_TYPE.CD)}
                    >
                      {formik.values.comprehensiveFees.cdDocName ??
                        formik.values.comprehensiveFees.document?.documentName}
                    </button>
                    <button
                      className="c-btn-icon"
                      type="button"
                      disabled={!isSite}
                      onClick={() => {
                        const formikField = formik.values.comprehensiveFees
                          .document
                          ? 'comprehensiveFees.document'
                          : 'comprehensiveFees.cdDocExists';
                        confirmDeleteDoc({
                          selectedAgreementField: 'comprehensiveFeesDoc',
                          formikField: formikField,
                          documentType: DOC_TYPE.CD,
                        });
                      }}
                    >
                      <i className="fal fa-trash-alt c-agreement-box__delete-btn"></i>
                    </button>
                  </>
                ) : (
                  <UploadDoc
                    handleSetFile={({
                      documentName,
                      file,
                    }: {
                      documentName: string;
                      file: any;
                    }) => {
                      formik.setFieldValue('comprehensiveFees.document', {
                        documentName,
                        file,
                      });
                    }}
                  />
                )}
              </div>
            </div>
          </div>

          {/* OTHER FEES */}

          <div className="c-agreement-box">
            <div className="c-agreement-box__grid-layout">
              <div className="c-agreement-box__align-top">
                <p className="c-agreement-box__title">
                  <Text tid="otherFees" />
                </p>
                <div className="l-flex-start">
                  <input
                    type="checkbox"
                    className="c-agreement-box__applicable-checkbox"
                    name="otherFees.isApplicable"
                    disabled={!isSite}
                    value={formik.values.otherFees.isApplicable.toString()}
                    checked={formik.values.otherFees.isApplicable}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  <p className="c-agreement-box__applicable-text">
                    <Text tid="applicable" />
                  </p>
                </div>
              </div>

              <div className="c-agreement-box__date">
                <label
                  htmlFor="active"
                  className={`${
                    !formik.values.otherFees.isApplicable
                      ? 'c-choose-date--disabled-label'
                      : ''
                  }`}
                >
                  <Text tid="invoiced" />
                </label>
                <div className="c-choose-date">
                  <DatePicker
                    placeholderText="choose date..."
                    className="c-date-picker"
                    dateFormat="yyyy/MM/dd"
                    disabled={!formik.values.otherFees.isApplicable || !isSite}
                    selected={
                      formik.values.otherFees.invoiceDate !== 'none'
                        ? new Date(formik.values.otherFees.invoiceDate)
                        : null
                    }
                    customInput={
                      <MaskedInput
                        mask={dateRegexPattern}
                        keepCharPositions={true}
                        guide={true}
                      />
                    }
                    onChange={(date) => {
                      formik.setFieldValue(
                        'otherFees.invoiceDate',
                        date === null ? 'none' : date
                      );
                    }}
                  />
                </div>
              </div>
              <div className="c-agreement-box__date">
                <label
                  htmlFor="active"
                  className={`${
                    !formik.values.otherFees.isApplicable
                      ? 'c-choose-date--disabled-label'
                      : ''
                  }`}
                >
                  <Text tid="poRecResent" />
                </label>
                <div className="c-choose-date">
                  <DatePicker
                    placeholderText="choose date..."
                    className="c-date-picker"
                    dateFormat="yyyy/MM/dd"
                    disabled={!formik.values.otherFees.isApplicable || !isSite}
                    selected={
                      formik.values.otherFees.receivedDate !== 'none'
                        ? new Date(formik.values.otherFees.receivedDate)
                        : null
                    }
                    customInput={
                      <MaskedInput
                        mask={dateRegexPattern}
                        keepCharPositions={true}
                        guide={true}
                      />
                    }
                    onChange={(date) => {
                      formik.setFieldValue(
                        'otherFees.receivedDate',
                        date === null ? 'none' : date
                      );
                    }}
                  />
                </div>
              </div>
              <div className="c-agreement-box__date">
                <label
                  htmlFor="active"
                  className={`${
                    !formik.values.otherFees.isApplicable
                      ? 'c-choose-date--disabled-label'
                      : ''
                  }`}
                >
                  <Text tid="paidPending" />
                </label>
                <div className="c-choose-date">
                  <DatePicker
                    placeholderText="choose date..."
                    className="c-date-picker"
                    dateFormat="yyyy/MM/dd"
                    disabled={!formik.values.otherFees.isApplicable || !isSite}
                    selected={
                      formik.values.otherFees.paidDate !== 'none'
                        ? new Date(formik.values.otherFees.paidDate)
                        : null
                    }
                    customInput={
                      <MaskedInput
                        mask={dateRegexPattern}
                        keepCharPositions={true}
                        guide={true}
                      />
                    }
                    onChange={(date) => {
                      formik.setFieldValue(
                        'otherFees.paidDate',
                        date === null ? 'none' : date
                      );
                    }}
                  />
                </div>
              </div>
              <div className="c-agreement-box__date">
                <label
                  htmlFor="active"
                  className={`${
                    !formik.values.otherFees.isApplicable
                      ? 'c-choose-date--disabled-label'
                      : ''
                  }`}
                >
                  <Text tid="paidToOem" />
                </label>
                <div className="c-choose-date">
                  <DatePicker
                    placeholderText="choose date..."
                    className="c-date-picker"
                    dateFormat="yyyy/MM/dd"
                    disabled={!formik.values.otherFees.isApplicable || !isSite}
                    selected={
                      formik.values.otherFees.paidToOem !== 'none'
                        ? new Date(formik.values.otherFees.paidToOem)
                        : null
                    }
                    customInput={
                      <MaskedInput
                        mask={dateRegexPattern}
                        keepCharPositions={true}
                        guide={true}
                      />
                    }
                    onChange={(date) => {
                      formik.setFieldValue(
                        'otherFees.paidToOem',
                        date === null ? 'none' : date
                      );
                    }}
                  />
                </div>
              </div>
              <div className="c-agreement-box__align-bottom l-flex-between c-agreement-box__doc-box">
                {formik.values.otherFees.odDocExists ||
                formik.values.otherFees.document ? (
                  <>
                    <button
                      className="c-btn-link-text c-agreement-box__doc-link"
                      type="button"
                      onClick={() => downloadDocument(DOC_TYPE.OD)}
                    >
                      {formik.values.otherFees.odDocName ??
                        formik.values.otherFees.document?.documentName}
                    </button>
                    <button
                      className="c-btn-icon"
                      type="button"
                      disabled={!isSite}
                      onClick={() => {
                        const formikField = formik.values.otherFees.document
                          ? 'otherFees.document'
                          : 'otherFees.odDocExists';
                        confirmDeleteDoc({
                          selectedAgreementField: 'otherFeesDoc',
                          formikField: formikField,
                          documentType: DOC_TYPE.OD,
                        });
                      }}
                    >
                      <i className="fal fa-trash-alt c-agreement-box__delete-btn"></i>
                    </button>
                  </>
                ) : (
                  <UploadDoc
                    handleSetFile={({
                      documentName,
                      file,
                    }: {
                      documentName: string;
                      file: any;
                    }) => {
                      formik.setFieldValue('otherFees.document', {
                        documentName,
                        file,
                      });
                    }}
                  />
                )}
              </div>
            </div>
          </div>

          {/* SUPPORT DOCS */}

          <div className="c-agreement-box">
            <div className="c-agreement-box__grid-layout-one-date">
              <div className="c-agreement-box__align-top">
                <p className="c-agreement-box__title">
                  <Text tid="supportDocs" />
                </p>
                <div className="l-flex-start">
                  <input
                    type="checkbox"
                    className="c-agreement-box__applicable-checkbox"
                    name="supportDocs.isApplicable"
                    disabled={!isSite}
                    value={formik.values.supportDocs.isApplicable.toString()}
                    checked={formik.values.supportDocs.isApplicable}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  <p className="c-agreement-box__applicable-text">
                    <Text tid="applicable" />
                  </p>
                </div>
              </div>

              <div className="c-agreement-box__date">
                <label
                  htmlFor="active"
                  className={`${
                    !formik.values.supportDocs.isApplicable
                      ? 'c-choose-date--disabled-label'
                      : ''
                  }`}
                >
                  <Text tid="received" />
                </label>
                <div className="c-choose-date">
                  <DatePicker
                    placeholderText="choose date..."
                    className="c-date-picker"
                    dateFormat="yyyy/MM/dd"
                    disabled={
                      !formik.values.supportDocs.isApplicable || !isSite
                    }
                    selected={
                      formik.values.supportDocs.receivedDate !== 'none'
                        ? new Date(formik.values.supportDocs.receivedDate)
                        : null
                    }
                    customInput={
                      <MaskedInput
                        mask={dateRegexPattern}
                        keepCharPositions={true}
                        guide={true}
                      />
                    }
                    onChange={(date) => {
                      formik.setFieldValue(
                        'supportDocs.receivedDate',
                        date === null ? 'none' : date
                      );
                    }}
                  />
                </div>
              </div>
              <div className="c-agreement-box__align-bottom l-flex-between c-agreement-box__doc-box">
                {formik.values.supportDocs.sdDocExists ||
                formik.values.supportDocs.document ? (
                  <>
                    <button
                      className="c-btn-link-text c-agreement-box__doc-link"
                      type="button"
                      onClick={() => downloadDocument(DOC_TYPE.SD)}
                    >
                      {formik.values.supportDocs.sdDocName ??
                        formik.values.supportDocs.document?.documentName}
                    </button>
                    <button
                      className="c-btn-icon"
                      type="button"
                      disabled={!isSite}
                      onClick={() => {
                        const formikField = formik.values.supportDocs.document
                          ? 'supportDocs.document'
                          : 'supportDocs.sdDocExists';
                        confirmDeleteDoc({
                          selectedAgreementField: 'supportDocsDoc',
                          formikField: formikField,
                          documentType: DOC_TYPE.SD,
                        });
                      }}
                    >
                      <i className="fal fa-trash-alt c-agreement-box__delete-btn"></i>
                    </button>
                  </>
                ) : (
                  <UploadDoc
                    handleSetFile={({
                      documentName,
                      file,
                    }: {
                      documentName: string;
                      file: any;
                    }) => {
                      formik.setFieldValue('supportDocs.document', {
                        documentName,
                        file,
                      });
                    }}
                  />
                )}
              </div>
            </div>
          </div>

          {/* INSURANCE DOCS */}

          <div className="c-agreement-box">
            <div className="c-agreement-box__grid-layout-license">
              <div className="c-agreement-box__align-top">
                <p className="c-agreement-box__title">
                  <Text tid="insurance" />
                </p>
                <div className="l-flex-start">
                  <input
                    type="checkbox"
                    className="c-agreement-box__applicable-checkbox"
                    name="insuranceDocs.isApplicable"
                    disabled={!isSite}
                    value={formik.values.insuranceDocs.isApplicable.toString()}
                    checked={formik.values.insuranceDocs.isApplicable}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  <p className="c-agreement-box__applicable-text">
                    <Text tid="applicable" />
                  </p>
                </div>
              </div>

              <div className="c-agreement-box__date">
                <label
                  htmlFor="active"
                  className={`${
                    !formik.values.insuranceDocs.isApplicable
                      ? 'c-choose-date--disabled-label'
                      : ''
                  }`}
                >
                  <Text tid="received" />
                </label>
                <div className="c-choose-date">
                  <DatePicker
                    placeholderText="choose date..."
                    className="c-date-picker"
                    dateFormat="yyyy/MM/dd"
                    disabled={
                      !formik.values.insuranceDocs.isApplicable || !isSite
                    }
                    selected={
                      formik.values.insuranceDocs.receivedDate !== 'none'
                        ? new Date(formik.values.insuranceDocs.receivedDate)
                        : null
                    }
                    customInput={
                      <MaskedInput
                        mask={dateRegexPattern}
                        keepCharPositions={true}
                        guide={true}
                      />
                    }
                    onChange={(date) => {
                      formik.setFieldValue(
                        'insuranceDocs.receivedDate',
                        date === null ? 'none' : date
                      );
                    }}
                  />
                </div>
              </div>
              <div className="c-agreement-box__date">
                <label
                  htmlFor="active"
                  className={`${
                    !formik.values.insuranceDocs.isApplicable
                      ? 'c-choose-date--disabled-label'
                      : ''
                  }`}
                >
                  <Text tid="effective" />
                </label>
                <div className="c-choose-date">
                  <DatePicker
                    placeholderText="choose date..."
                    className="c-date-picker"
                    dateFormat="yyyy/MM/dd"
                    disabled={
                      !formik.values.insuranceDocs.isApplicable || !isSite
                    }
                    selected={
                      formik.values.insuranceDocs.effectiveDate !== 'none'
                        ? new Date(formik.values.insuranceDocs.effectiveDate)
                        : null
                    }
                    customInput={
                      <MaskedInput
                        mask={dateRegexPattern}
                        keepCharPositions={true}
                        guide={true}
                      />
                    }
                    onChange={(date) => {
                      formik.setFieldValue(
                        'insuranceDocs.effectiveDate',
                        date === null ? 'none' : date
                      );
                    }}
                  />
                </div>
              </div>
              <div className="c-agreement-box__date">
                <label
                  htmlFor="active"
                  className={`${
                    !formik.values.insuranceDocs.isApplicable
                      ? 'c-choose-date--disabled-label'
                      : ''
                  }`}
                >
                  <Text tid="expires" />
                </label>
                <div className="c-choose-date">
                  <DatePicker
                    placeholderText="choose date..."
                    className="c-date-picker"
                    dateFormat="yyyy/MM/dd"
                    disabled={
                      !formik.values.insuranceDocs.isApplicable || !isSite
                    }
                    selected={
                      formik.values.insuranceDocs.expirationDate !== 'none'
                        ? new Date(formik.values.insuranceDocs.expirationDate)
                        : null
                    }
                    customInput={
                      <MaskedInput
                        mask={dateRegexPattern}
                        keepCharPositions={true}
                        guide={true}
                      />
                    }
                    onChange={(date) => {
                      formik.setFieldValue(
                        'insuranceDocs.expirationDate',
                        date === null ? 'none' : date
                      );
                    }}
                  />
                </div>
              </div>
              <div className="c-agreement-box__align-bottom c-agreement-box__doc-box l-flex-between">
                {formik.values.insuranceDocs.idDocExists ||
                formik.values.insuranceDocs.document ? (
                  <>
                    <button
                      className="c-btn-link-text c-agreement-box__doc-link"
                      type="button"
                      onClick={() => downloadDocument(DOC_TYPE.ID)}
                    >
                      {formik.values.insuranceDocs.idDocName ??
                        formik.values.insuranceDocs.document?.documentName}
                    </button>
                    <button
                      className="c-btn-icon"
                      type="button"
                      disabled={!isSite}
                      onClick={() => {
                        const formikField = formik.values.supportDocs.document
                          ? 'insuranceDocs.document'
                          : 'insuranceDocs.idDocExists';
                        confirmDeleteDoc({
                          selectedAgreementField: 'insuranceDocsDoc',
                          formikField: formikField,
                          documentType: DOC_TYPE.ID,
                        });
                      }}
                    >
                      <i className="fal fa-trash-alt c-agreement-box__delete-btn"></i>
                    </button>
                  </>
                ) : (
                  <UploadDoc
                    handleSetFile={({
                      documentName,
                      file,
                    }: {
                      documentName: string;
                      file: any;
                    }) => {
                      formik.setFieldValue('insuranceDocs.document', {
                        documentName,
                        file,
                      });
                    }}
                  />
                )}
              </div>
            </div>
          </div>

          {/* ANNUAL FEES */}

          <div className="c-agreement-box">
            <div className="c-agreement-box__grid-layout-annual">
              <label className="c-agreement-box__title">
                <Text tid="annualFees" />
              </label>

              {/* Titles Row */}

              <label htmlFor="year">
                <Text tid="modelYear" />
              </label>
              <label
                htmlFor="active"
                className={`${
                  !formik.values.annualFees.isApplicable
                    ? 'c-choose-date--disabled-label'
                    : ''
                }`}
              >
                <Text tid="invoiced" />
              </label>
              <label
                htmlFor="active"
                className={`${
                  !formik.values.annualFees.isApplicable
                    ? 'c-choose-date--disabled-label'
                    : ''
                }`}
              >
                <Text tid="poRecResent" />
              </label>
              <label
                htmlFor="active"
                className={`${
                  !formik.values.annualFees.isApplicable
                    ? 'c-choose-date--disabled-label'
                    : ''
                }`}
              >
                <Text tid="paidPending" />
              </label>
              <label
                htmlFor="active"
                className={`${
                  !formik.values.annualFees.isApplicable
                    ? 'c-choose-date--disabled-label'
                    : ''
                }`}
              >
                <Text tid="paidToOem" />
              </label>
              <div>{/* empty div above Upload Doc */}</div>

              {formik.values.annualFees.feeHistory.map((entry, i) => {
                return (
                  <>
                    <div className="c-agreement-box__align-top c-agreement-box__title-box">
                      {i === 0 ? (
                        <div className="l-flex-start">
                          <input
                            type="checkbox"
                            className="c-agreement-box__applicable-checkbox"
                            name="annualFees.isApplicable"
                            disabled={!isSite}
                            value={formik.values.annualFees.isApplicable.toString()}
                            checked={formik.values.annualFees.isApplicable}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                          <p className="c-agreement-box__applicable-text">
                            <Text tid="applicable" />
                          </p>
                        </div>
                      ) : (
                        <div className="l-flex-start"></div>
                      )}
                    </div>
                    <div className="c-agreement-box__date justify-center padding-bottom">
                      <div>
                        <Typeahead
                          id="modelYear"
                          options={yearsList}
                          disabled={!isSite}
                          selected={entry.modelYear}
                          onChange={(selected) => {
                            formik.setFieldValue(
                              `annualFees.feeHistory[${i}].modelYear`,
                              selected
                            );
                          }}
                          positionFixed
                          placeholder={TextOnly('selectAYear')}
                        />
                      </div>
                    </div>

                    <div className="c-agreement-box__date justify-center">
                      <div className="c-choose-date">
                        <DatePicker
                          placeholderText="choose date..."
                          className="c-date-picker"
                          dateFormat="yyyy/MM/dd"
                          disabled={
                            !formik.values.annualFees.isApplicable || !isSite
                          }
                          selected={
                            entry.invoiceDate !== 'none'
                              ? new Date(entry.invoiceDate)
                              : null
                          }
                          customInput={
                            <MaskedInput
                              mask={dateRegexPattern}
                              keepCharPositions={true}
                              guide={true}
                            />
                          }
                          onChange={(date) => {
                            formik.setFieldValue(
                              `annualFees.feeHistory[${i}].invoiceDate`,
                              date === null ? 'none' : date
                            );
                          }}
                        />
                      </div>
                    </div>
                    <div className="c-agreement-box__date justify-center">
                      <div className="c-choose-date">
                        <DatePicker
                          placeholderText="choose date..."
                          className="c-date-picker"
                          dateFormat="yyyy/MM/dd"
                          disabled={
                            !formik.values.annualFees.isApplicable || !isSite
                          }
                          selected={
                            entry.receivedDate !== 'none'
                              ? new Date(entry.receivedDate)
                              : null
                          }
                          customInput={
                            <MaskedInput
                              mask={dateRegexPattern}
                              keepCharPositions={true}
                              guide={true}
                            />
                          }
                          onChange={(date) => {
                            formik.setFieldValue(
                              `annualFees.feeHistory[${i}].receivedDate`,
                              date === null ? 'none' : date
                            );
                          }}
                        />
                      </div>
                    </div>
                    <div className="c-agreement-box__date justify-center">
                      <div className="c-choose-date">
                        <DatePicker
                          placeholderText="choose date..."
                          className="c-date-picker"
                          dateFormat="yyyy/MM/dd"
                          disabled={
                            !formik.values.annualFees.isApplicable || !isSite
                          }
                          selected={
                            entry.paidDate !== 'none'
                              ? new Date(entry.paidDate)
                              : null
                          }
                          customInput={
                            <MaskedInput
                              mask={dateRegexPattern}
                              keepCharPositions={true}
                              guide={true}
                            />
                          }
                          onChange={(date) => {
                            formik.setFieldValue(
                              `annualFees.feeHistory[${i}].paidDate`,
                              date === null ? 'none' : date
                            );
                          }}
                        />
                      </div>
                    </div>
                    <div className="c-agreement-box__date justify-center">
                      <div className="c-choose-date">
                        <DatePicker
                          placeholderText="choose date..."
                          className="c-date-picker"
                          dateFormat="yyyy/MM/dd"
                          disabled={
                            !formik.values.annualFees.isApplicable || !isSite
                          }
                          selected={
                            entry.paidToOem !== 'none'
                              ? new Date(entry.paidToOem)
                              : null
                          }
                          customInput={
                            <MaskedInput
                              mask={dateRegexPattern}
                              keepCharPositions={true}
                              guide={true}
                            />
                          }
                          onChange={(date) => {
                            formik.setFieldValue(
                              `annualFees.feeHistory[${i}].paidToOem`,
                              date === null ? 'none' : date
                            );
                          }}
                        />
                      </div>
                    </div>
                    <div className="l-flex-between c-agreement-box__doc-box">
                      {entry.adDocExists || entry.document ? (
                        <>
                          <button
                            className="c-btn-link-text c-agreement-box__doc-link"
                            type="button"
                            onClick={() =>
                              downloadDocument(DOC_TYPE.AD, entry.modelYear)
                            }
                          >
                            {entry.adDocExists
                              ? entry.adDocName
                              : entry.document?.documentName}
                          </button>
                          <button
                            className="c-btn-icon"
                            type="button"
                            disabled={!isSite}
                            onClick={() => {
                              const formikField = formik.values.annualFees
                                .feeHistory[i].document
                                ? `annualFees.feeHistory.[${i}].document`
                                : `annualFees.feeHistory.[${i}].adDocExists`;
                              confirmDeleteDoc({
                                selectedAgreementField: `annualFees.feeHistory.[${i}].annualFeesDoc`,
                                formikField: formikField,
                                documentType: DOC_TYPE.AD,
                                modelYear: entry.modelYear.join(','),
                              });
                            }}
                          >
                            <i className="fal fa-trash-alt c-agreement-box__delete-btn"></i>
                          </button>
                        </>
                      ) : (
                        <UploadDoc
                          handleSetFile={({
                            documentName,
                            file,
                          }: {
                            documentName: string;
                            file: any;
                          }) => {
                            formik.setFieldValue(
                              `annualFees.feeHistory[${i}].document`,
                              {
                                documentName,
                                file,
                              }
                            );
                          }}
                        />
                      )}
                    </div>
                  </>
                );
              })}
            </div>
            <div className="l-flex-between">
              <button
                className="c-btn-link-bare c-agreement-box__new-year"
                type="button"
                disabled={
                  formik.values.annualFees.feeHistory?.[0]?.modelYear.length ===
                    0 || !isSite
                }
                onClick={handleAddNewYear}
              >
                <div className="c-btn__inner">
                  <i className="c-btn__icon fal fa-plus" />
                  <Text tid="newYear" />
                </div>
              </button>

              {/* *************************************************************************** */}

              {/* DO NOT REMOVE! Accordion is not needed now, but may be needed in the future */}

              {/* *************************************************************************** */}

              {/* <button
                className="c-btn-link-text c-agreement-box__see-more"
                onClick={toggleAccordion}
                type="button"
              >
                <div className="c-btn__inner">
                  {!showAccordion ? 'See More' : 'See Less'}
                </div>
              </button> */}
            </div>
            {/* {showAccordion ? (
              <>
                {formik.values.annualFees.feeHistory?.map(
                  (fee: FormikFeeHistory, index: number) =>
                    index !== 0 ? (
                      <div
                        className="c-agreement-box__annual-fees-grid-layout"
                        key={index}
                      >
                        <p>{formatModelYears(fee.modelYear.join(','))}</p>
                        <p>
                          {fee.invoiceDate !== 'none'
                            ? moment(fee.invoiceDate).format('YYYY-MM-DD')
                            : '-'}
                        </p>
                        <p>
                          {fee.receivedDate !== 'none'
                            ? moment(fee.receivedDate).format('YYYY-MM-DD')
                            : '-'}
                        </p>
                        <p>
                          {fee.paidDate !== 'none'
                            ? moment(fee.paidDate).format('YYYY-MM-DD')
                            : '-'}
                        </p>
                        <p>
                          {fee.paidToOem !== 'none'
                            ? moment(fee.paidToOem).format('YYYY-MM-DD')
                            : '-'}
                        </p>
                        {isLoadingPreviousAnnualFeesDoc ? (
                          <Loading className="loading-small" />
                        ) : fee.document ? (
                          <button
                            className="c-btn-link-text c-agreement-box__doc-link"
                            type="button"
                            onClick={() =>
                              downloadDocument({
                                url: fee?.document?.documentUrl as string,
                                fileName: fee?.document?.documentName as string,
                              })
                            }
                          >
                            {fee.document.documentName}
                          </button>
                        ) : (
                          <p>-</p>
                        )}
                      </div>
                    ) : null
                )}
              </>
            ) : null} */}
          </div>

          <LoaderButton
            type="submit"
            id="submit-button"
            disabled={
              !formik.isValid ||
              !!formik.values.annualFees.feeHistory?.find(
                (fh) => !fh.modelYear || fh.modelYear.length === 0
              )
            }
            isLoading={
              formik.isSubmitting ||
              siteAdminUpdateAgreementMutation.isLoading ||
              siteAdminUploadDocumentMutation.isLoading
            }
            text={TextOnly('update')}
            loadingText={TextOnly('updating')}
          />
        </form>
      </div>
    </Dialog>
  );
};
