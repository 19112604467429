import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { verifyEmail } from '../libs/db-lib';
import { Loading } from '../components/Loading';

interface VerifyEmailProps {
  code: string;
  username: string;
  clientId: string;
  email: string;
}
export const VerifyEmail = (props: VerifyEmailProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const code = queryParams.get('code');
  const username = queryParams.get('username');
  const clientId = queryParams.get('clientId');
  const email = queryParams.get('email');

  useEffect(() => {
    const callVerifyEmail = async () => {
      const response = await verifyEmail(
        code || '',
        username || '',
        clientId || '',
        email || ''
      );
      navigate('/login', {
        state: {
          emailVerified: response?.error ? response.error : response.message,
          success: response?.error ? false : true,
          username: username,
        },
      });
    };
    callVerifyEmail();
  }, []);

  return (
    <div className="l-container-lg l-full-height">
      <div className="verifyEmail">
        <Loading />
      </div>
    </div>
  );
};
