import { Dialog } from '@reach/dialog';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import DatePicker from 'react-datepicker';
import MaskedInput from 'react-text-mask';
import { Loading } from '../../components/Loading';
import LoaderButton from '../../components/LoaderButton';
import { TextOnly, Text } from '../../components/Text';
import { UploadDoc } from '../../components/UploadDoc/UploadDoc';
import { AGREEMENT_SECTIONS, DOC_TYPE } from '../../CONSTANTS';
import { useConfirm } from '../../context/Confirm';
import { getDocumentUrl } from '../../libs/db-lib';
import { formatModelYears } from '../../libs/utils-ts';
import {
  AgreementDocument,
  FeeHistory,
  UpdateAgreement,
  Vendor,
} from '../../types';
import {
  adminUploadDocumentUrl,
  adminRemoveDocument,
  adminUpdateAgreement,
} from '../../libs/db-lib';

interface VendorViewAgreementSidebarProps {
  oemVendors: Vendor[];
  toolVendors: Vendor[];
  toggleDialog: () => void;
  selectedAgreement: UpdateAgreement;
  setSelectedAgreement: (agreement: UpdateAgreement) => void;
}

interface AllDocsProps {
  licenseAgreementDoc?: AgreementDocument;
  comprehensiveFeesDoc?: AgreementDocument;
  otherFeesDoc?: AgreementDocument;
  insuranceDocsDoc?: AgreementDocument;
  supportDocsDoc?: AgreementDocument;
}

const dateRegexPattern = [
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  '/',
  /\d/,
  /\d/,
  '/',
  /\d/,
  /\d/,
];

export const VendorViewAgreementSidebar = ({
  toggleDialog,
  oemVendors,
  toolVendors,
  selectedAgreement,
  setSelectedAgreement,
}: VendorViewAgreementSidebarProps) => {
  const { handleSetConfirmProps } = useConfirm();

  const [showAccordion, setShowAccordion] = useState(false);
  const [feeHistory, setFeeHistory] = useState<FeeHistory[]>(
    selectedAgreement.annualFees.feeHistory?.sort((a, b) => {
      return b.modelYear < a.modelYear ? -1 : 1;
    })
  );
  const [insuranceDocs, setInsuranceDocs] = useState(
    selectedAgreement.insuranceDocs
  );
  const [isLoading, setIsLoading] = useState(false);

  const toggleAccordion = () => {
    setShowAccordion(!showAccordion);
  };

  const downloadDocument = async (docType: DOC_TYPE, modelYear?: string) => {
    let docName = '';
    await getDocumentUrl({
      agreementID: selectedAgreement?.agreementID as string,
      documentType: docType,
      modelYear: modelYear,
    })
      .then(async (doc: AgreementDocument) => {
        docName = doc.documentName;
        if (doc.documentUrl) {
          return await fetch(doc.documentUrl);
        } else {
          return new Error('No documentUrl');
        }
      })
      .then(async (response: any) => {
        const blob = await response.blob();
        const file = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.style.display = 'none';
        document.body.appendChild(link);
        link.href = file;
        link.setAttribute('download', `${docName}`);
        link.click();
        window.URL.revokeObjectURL(link.href);
        document.body.removeChild(link);
      })
      .catch((error) => {
        handleSetConfirmProps({
          title: 'Error',
          message: error?.error,
        });
      });
  };

  const saveInsuranceDocs = async () => {
    setIsLoading(true);

    // if document has changed
    if (insuranceDocs.document?.file) {
      const docFields = {
        roleType: 'TOOL_ADMIN',
        agreementID: selectedAgreement.agreementID,
        documentType: DOC_TYPE.ID,
        documentName: insuranceDocs.document.documentName,
        file: insuranceDocs.document.file,
      };
      await adminUploadDocumentUrl(docFields);
    }

    const agreementFields = {
      roleType: 'TOOL_ADMIN',
      agreementID: selectedAgreement.agreementID,
      oemVendorID: selectedAgreement.oemVendorID,
      insuranceDocs: {
        isApplicable: selectedAgreement.insuranceDocs.isApplicable,
        receivedDate:
          insuranceDocs?.receivedDate !== 'none'
            ? moment(insuranceDocs.receivedDate).format()
            : 'none',
        expirationDate:
          insuranceDocs?.expirationDate !== 'none'
            ? moment(insuranceDocs.expirationDate).format()
            : 'none',
        effectiveDate:
          insuranceDocs?.effectiveDate !== 'none'
            ? moment(insuranceDocs.effectiveDate).format()
            : 'none',
      },
    };
    await adminUpdateAgreement(agreementFields);

    setIsLoading(false);
  };

  const confirmDeleteDoc = async () => {
    setIsLoading(true);

    const docFields = {
      roleType: 'TOOL_ADMIN',
      agreementID: selectedAgreement.agreementID,
      documentType: DOC_TYPE.ID,
      documentName: insuranceDocs.document?.documentName,
      file: insuranceDocs.document?.file,
    };
    await adminRemoveDocument(docFields);
    insuranceDocs.idDocExists = false;
    setInsuranceDocs(insuranceDocs);
    setIsLoading(false);
  };

  if (selectedAgreement === null) {
    return <Loading />;
  }

  return (
    <Dialog
      isOpen={true}
      onDismiss={toggleDialog}
      className="c-modal-slider c-modal-slider--wide"
      aria-label={TextOnly('viewAgreement')}
    >
      <button
        className="c-btn-icon c-modal-slider__close"
        onClick={toggleDialog}
      >
        <div className="c-btn__inner">
          <i className="c-btn__icon fa fa-times" />
        </div>
      </button>
      <h1 className="c-modal__heading">
        <Text tid="viewAgreement" />
      </h1>

      <div className="c-modal__body">
        <div className="c-agreement-box">
          <div className="c-agreement-box__grid-layout-header">
            {oemVendors.length > 0 ? (
              <div className="c-agreement-box__select no-padding">
                <p className="c-agreement-box__title no-top-margin">
                  <Text tid="oemMfr" />
                </p>
                <p className="u-margin-top-small">
                  {oemVendors?.find(
                    (oem) =>
                      oem.vendorID === (selectedAgreement.oemVendorID as string)
                  )?.vendorName || 'Oem Mfr'}
                </p>
              </div>
            ) : null}
            {toolVendors.length > 0 ? (
              <div className="c-agreement-box__select no-padding">
                <p className="c-agreement-box__title no-top-margin">
                  <Text tid="toolMfr" />
                </p>
                <p className="u-margin-top-small">
                  {toolVendors?.find(
                    (tool) =>
                      tool.vendorID ===
                      (selectedAgreement.toolVendorID as string)
                  )?.vendorName || 'Tool Mfr'}
                </p>
              </div>
            ) : null}
            <div className="c-agreement-box__select no-padding">
              <p className="c-agreement-box__title no-top-margin">
                <Text tid="region" />
              </p>
              <p className="u-margin-top-small">
                {TextOnly(selectedAgreement.agreementRegion)}
              </p>
            </div>
            <div className="c-agreement-box__select no-padding">
              <p className="c-agreement-box__title no-top-margin">
                <Text tid="created" />
              </p>
              <p className="u-margin-top-small">
                {moment(selectedAgreement.createdOn).format('YYYY-MM-DD')}
              </p>
            </div>
          </div>
        </div>

        {/* LICENSE AGREEMENT */}

        <div className="c-agreement-box">
          <div className="c-agreement-box__grid-layout-license">
            <div className="c-agreement-box__align-top">
              <p className="c-agreement-box__title">
                <Text tid="licenseAgreement" />
              </p>
            </div>
            <div
              className={`c-agreement-box__date ${
                selectedAgreement.invalidSections?.includes(
                  AGREEMENT_SECTIONS.LICENSE_AGREEMENT
                )
                  ? 'c-agreement-box__date--error'
                  : ''
              }`}
            >
              <label htmlFor="active">
                <Text tid="received" />
              </label>
              <p className="u-margin-top-small">
                {selectedAgreement.licenseAgreement.receivedDate !== 'none'
                  ? moment(
                      selectedAgreement.licenseAgreement.receivedDate
                    ).format('YYYY-MM-DD')
                  : '-'}
              </p>
            </div>
            <div
              className={`c-agreement-box__date ${
                selectedAgreement.invalidSections?.includes(
                  AGREEMENT_SECTIONS.LICENSE_AGREEMENT
                )
                  ? 'c-agreement-box__date--error'
                  : ''
              }`}
            >
              <label htmlFor="active">
                <Text tid="sent" />
              </label>
              <p className="u-margin-top-small">
                {selectedAgreement.licenseAgreement.sentDate !== 'none'
                  ? moment(selectedAgreement.licenseAgreement.sentDate).format(
                      'YYYY-MM-DD'
                    )
                  : '-'}
              </p>
            </div>
            <div
              className={`c-agreement-box__date ${
                selectedAgreement.invalidSections?.includes(
                  AGREEMENT_SECTIONS.LICENSE_AGREEMENT
                )
                  ? 'c-agreement-box__date--error'
                  : ''
              }`}
            >
              <label htmlFor="active">
                <Text tid="signed" />
              </label>
              <p className="u-margin-top-small">
                {selectedAgreement.licenseAgreement.signedDate !== 'none'
                  ? moment(
                      selectedAgreement.licenseAgreement.signedDate
                    ).format('YYYY-MM-DD')
                  : '-'}
              </p>
            </div>
            <div className="l-flex-between c-agreement-box__doc-box">
              {selectedAgreement.licenseAgreement.ldDocExists ? (
                <div className="l-flex-between c-agreement-box__doc-box">
                  <button
                    className="c-btn-link-text c-agreement-box__doc-link"
                    type="button"
                    onClick={() => downloadDocument(DOC_TYPE.LD)}
                  >
                    {selectedAgreement.licenseAgreement.ldDocName}
                  </button>
                </div>
              ) : null}
            </div>
          </div>
          {selectedAgreement.agreementNotes ? (
            <div className="c-agreement-box__textbox-grid-layout">
              <textarea
                className="c-textarea"
                disabled
                placeholder="Enter text here..."
                name="agreementNotes"
                rows={3}
                value={selectedAgreement.agreementNotes}
              />
            </div>
          ) : null}
        </div>

        {/* COMP FEES */}

        <div className="c-agreement-box">
          <div className="c-agreement-box__grid-layout">
            <div className="c-agreement-box__align-top">
              <p className="c-agreement-box__title">
                <Text tid="compFees" />
              </p>
              <div className="l-flex-start u-margin-top-small">
                <input
                  type="checkbox"
                  className="c-agreement-box__applicable-checkbox"
                  name="comprehensiveFees.isApplicable"
                  value={selectedAgreement.comprehensiveFees.isApplicable.toString()}
                  checked={selectedAgreement.comprehensiveFees.isApplicable}
                  readOnly
                />
                <p className="c-agreement-box__applicable-text">
                  <Text tid="applicable" />
                </p>
              </div>
            </div>

            <div
              className={`c-agreement-box__date ${
                selectedAgreement.invalidSections?.includes(
                  AGREEMENT_SECTIONS.COMPREHENSIVE_FEE
                )
                  ? 'c-agreement-box__date--error'
                  : ''
              }`}
            >
              <label htmlFor="active">
                <Text tid="invoiced" />
              </label>
              <p className="u-margin-top-small">
                {selectedAgreement.comprehensiveFees.invoiceDate !== 'none'
                  ? moment(
                      selectedAgreement.comprehensiveFees.invoiceDate
                    ).format('YYYY-MM-DD')
                  : '-'}
              </p>
            </div>
            <div
              className={`c-agreement-box__date ${
                selectedAgreement.invalidSections?.includes(
                  AGREEMENT_SECTIONS.COMPREHENSIVE_FEE
                )
                  ? 'c-agreement-box__date--error'
                  : ''
              }`}
            >
              <label htmlFor="active">
                <Text tid="poRecResent" />
              </label>
              <p className="u-margin-top-small">
                {selectedAgreement.comprehensiveFees.receivedDate !== 'none'
                  ? moment(
                      selectedAgreement.comprehensiveFees.receivedDate
                    ).format('YYYY-MM-DD')
                  : '-'}
              </p>
            </div>
            <div
              className={`c-agreement-box__date ${
                selectedAgreement.invalidSections?.includes(
                  AGREEMENT_SECTIONS.COMPREHENSIVE_FEE
                )
                  ? 'c-agreement-box__date--error'
                  : ''
              }`}
            >
              <label htmlFor="active">
                <Text tid="paidPending" />
              </label>
              <p className="u-margin-top-small">
                {selectedAgreement.comprehensiveFees.paidDate !== 'none'
                  ? moment(selectedAgreement.comprehensiveFees.paidDate).format(
                      'YYYY-MM-DD'
                    )
                  : '-'}
              </p>
            </div>
            <div
              className={`c-agreement-box__date ${
                selectedAgreement.invalidSections?.includes(
                  AGREEMENT_SECTIONS.COMPREHENSIVE_FEE
                )
                  ? 'c-agreement-box__date--error'
                  : ''
              }`}
            >
              <label htmlFor="active">
                <Text tid="paidToOem" />
              </label>
              <p className="u-margin-top-small">
                {selectedAgreement.comprehensiveFees.paidToOem !== 'none'
                  ? moment(
                      selectedAgreement.comprehensiveFees.paidToOem
                    ).format('YYYY-MM-DD')
                  : '-'}
              </p>
            </div>
            <div className="l-flex-between c-agreement-box__doc-box">
              {selectedAgreement.comprehensiveFees.cdDocExists ? (
                <div className="l-flex-between c-agreement-box__doc-box">
                  <button
                    className="c-btn-link-text c-agreement-box__doc-link"
                    type="button"
                    onClick={() => downloadDocument(DOC_TYPE.CD)}
                  >
                    {selectedAgreement.comprehensiveFees.cdDocName}
                  </button>
                </div>
              ) : null}
            </div>
          </div>
        </div>

        {/* OTHER FEES */}

        <div className="c-agreement-box">
          <div className="c-agreement-box__grid-layout">
            <div className="c-agreement-box__align-top">
              <p className="c-agreement-box__title">
                <Text tid="otherFees" />
              </p>
              <div className="l-flex-start u-margin-top-small">
                <input
                  type="checkbox"
                  className="c-agreement-box__applicable-checkbox"
                  name="otherFees.isApplicable"
                  value={selectedAgreement.otherFees.isApplicable.toString()}
                  checked={selectedAgreement.otherFees.isApplicable}
                  readOnly
                />
                <p className="c-agreement-box__applicable-text">
                  <Text tid="applicable" />
                </p>
              </div>
            </div>

            <div
              className={`c-agreement-box__date ${
                selectedAgreement.invalidSections?.includes(
                  AGREEMENT_SECTIONS.OTHER_FEE
                )
                  ? 'c-agreement-box__date--error'
                  : ''
              }`}
            >
              <label htmlFor="active">
                <Text tid="invoiced" />
              </label>
              <p className="u-margin-top-small">
                {selectedAgreement.otherFees.invoiceDate !== 'none'
                  ? moment(selectedAgreement.otherFees.invoiceDate).format(
                      'YYYY-MM-DD'
                    )
                  : '-'}
              </p>
            </div>
            <div
              className={`c-agreement-box__date ${
                selectedAgreement.invalidSections?.includes(
                  AGREEMENT_SECTIONS.OTHER_FEE
                )
                  ? 'c-agreement-box__date--error'
                  : ''
              }`}
            >
              <label htmlFor="active">
                <Text tid="poRecResent" />
              </label>
              <p className="u-margin-top-small">
                {selectedAgreement.otherFees.receivedDate !== 'none'
                  ? moment(selectedAgreement.otherFees.receivedDate).format(
                      'YYYY-MM-DD'
                    )
                  : '-'}
              </p>
            </div>
            <div
              className={`c-agreement-box__date ${
                selectedAgreement.invalidSections?.includes(
                  AGREEMENT_SECTIONS.OTHER_FEE
                )
                  ? 'c-agreement-box__date--error'
                  : ''
              }`}
            >
              <label htmlFor="active">
                <Text tid="paidPending" />
              </label>
              <p className="u-margin-top-small">
                {selectedAgreement.otherFees.paidDate !== 'none'
                  ? moment(selectedAgreement.otherFees.paidDate).format(
                      'YYYY-MM-DD'
                    )
                  : '-'}
              </p>
            </div>
            <div
              className={`c-agreement-box__date ${
                selectedAgreement.invalidSections?.includes(
                  AGREEMENT_SECTIONS.OTHER_FEE
                )
                  ? 'c-agreement-box__date--error'
                  : ''
              }`}
            >
              <label htmlFor="active">
                <Text tid="paidToOem" />
              </label>
              <p className="u-margin-top-small">
                {selectedAgreement.otherFees.paidToOem !== 'none'
                  ? moment(selectedAgreement.otherFees.paidToOem).format(
                      'YYYY-MM-DD'
                    )
                  : '-'}
              </p>
            </div>
            <div className="l-flex-between c-agreement-box__doc-box">
              {selectedAgreement.otherFees.odDocExists ? (
                <div className="l-flex-between c-agreement-box__doc-box">
                  <button
                    className="c-btn-link-text c-agreement-box__doc-link"
                    type="button"
                    onClick={() => downloadDocument(DOC_TYPE.OD)}
                  >
                    {selectedAgreement.otherFees.odDocName}
                  </button>
                </div>
              ) : null}
            </div>
          </div>
        </div>

        {/* SUPPORT DOCS */}

        <div className="c-agreement-box">
          <div className="c-agreement-box__grid-layout-one-date">
            <div className="c-agreement-box__align-top">
              <p className="c-agreement-box__title">
                <Text tid="supportDocs" />
              </p>
              <div className="l-flex-start u-margin-top-small">
                <input
                  type="checkbox"
                  className="c-agreement-box__applicable-checkbox"
                  name="supportDocs.isApplicable"
                  value={selectedAgreement.supportDocs.isApplicable.toString()}
                  checked={selectedAgreement.supportDocs.isApplicable}
                  readOnly
                />
                <p className="c-agreement-box__applicable-text">
                  <Text tid="applicable" />
                </p>
              </div>
            </div>

            <div
              className={`c-agreement-box__date ${
                selectedAgreement.invalidSections?.includes(
                  AGREEMENT_SECTIONS.SUPPORT_DOC
                )
                  ? 'c-agreement-box__date--error'
                  : ''
              }`}
            >
              <label htmlFor="active">
                <Text tid="received" />
              </label>
              <p className="u-margin-top-small">
                {selectedAgreement.supportDocs.receivedDate !== 'none'
                  ? moment(selectedAgreement.supportDocs.receivedDate).format(
                      'YYYY-MM-DD'
                    )
                  : '-'}
              </p>
            </div>
            <div className="l-flex-between c-agreement-box__doc-box">
              {selectedAgreement.supportDocs.sdDocExists ? (
                <div className="l-flex-between c-agreement-box__doc-box">
                  <button
                    className="c-btn-link-text c-agreement-box__doc-link"
                    type="button"
                    onClick={() => downloadDocument(DOC_TYPE.SD)}
                  >
                    {selectedAgreement.supportDocs.sdDocName}
                  </button>
                </div>
              ) : null}
            </div>
          </div>
        </div>

        {/* INSURANCE DOCS */}

        <div className="c-agreement-box">
          <div className="c-agreement-box__grid-layout-license">
            <div className="c-agreement-box__align-top">
              <p className="c-agreement-box__title">
                <Text tid="insurance" />
              </p>
              <div className="l-flex-start u-margin-top-small">
                <input
                  type="checkbox"
                  className="c-agreement-box__applicable-checkbox"
                  name="insuranceDocs.isApplicable"
                  value={selectedAgreement.insuranceDocs.isApplicable.toString()}
                  checked={selectedAgreement.insuranceDocs.isApplicable}
                  readOnly
                />
                <p className="c-agreement-box__applicable-text">
                  <Text tid="applicable" />
                </p>
              </div>
            </div>

            <div
              className={`c-agreement-box__date ${
                selectedAgreement.invalidSections?.includes(
                  AGREEMENT_SECTIONS.INSURANCE_DOC
                )
                  ? 'c-agreement-box__date--error'
                  : ''
              }`}
            >
              <label htmlFor="active">
                <Text tid="received" />
              </label>
              {insuranceDocs.isApplicable ? (
                <div className="c-choose-date">
                  <DatePicker
                    placeholderText="choose date..."
                    className="c-date-picker"
                    dateFormat="yyyy/MM/dd"
                    disabled={!insuranceDocs.isApplicable}
                    selected={
                      insuranceDocs.receivedDate !== 'none'
                        ? new Date(insuranceDocs.receivedDate)
                        : null
                    }
                    customInput={
                      <MaskedInput
                        mask={dateRegexPattern}
                        keepCharPositions={true}
                        guide={true}
                      />
                    }
                    onChange={(date) => {
                      const newInsuranceDocs = { ...insuranceDocs };
                      newInsuranceDocs.receivedDate =
                        date === null ? 'none' : moment(date).format();
                      setInsuranceDocs(newInsuranceDocs);
                    }}
                  />
                </div>
              ) : (
                <p className="u-margin-top-small">
                  {selectedAgreement.insuranceDocs.receivedDate !== 'none'
                    ? moment(
                        selectedAgreement.insuranceDocs.receivedDate
                      ).format('YYYY-MM-DD')
                    : '-'}
                </p>
              )}
            </div>
            <div
              className={`c-agreement-box__date ${
                selectedAgreement.invalidSections?.includes(
                  AGREEMENT_SECTIONS.INSURANCE_DOC
                )
                  ? 'c-agreement-box__date--error'
                  : ''
              }`}
            >
              <label htmlFor="active">
                <Text tid="effective" />
              </label>
              {insuranceDocs.isApplicable ? (
                <div className="c-choose-date">
                  <DatePicker
                    placeholderText="choose date..."
                    className="c-date-picker"
                    dateFormat="yyyy/MM/dd"
                    disabled={!insuranceDocs.isApplicable}
                    selected={
                      insuranceDocs.effectiveDate !== 'none'
                        ? new Date(insuranceDocs.effectiveDate)
                        : null
                    }
                    customInput={
                      <MaskedInput
                        mask={dateRegexPattern}
                        keepCharPositions={true}
                        guide={true}
                      />
                    }
                    onChange={(date) => {
                      const newInsuranceDocs = { ...insuranceDocs };
                      newInsuranceDocs.effectiveDate =
                        date === null ? 'none' : moment(date).format();
                      setInsuranceDocs(newInsuranceDocs);
                    }}
                  />
                </div>
              ) : (
                <p className="u-margin-top-small">
                  {insuranceDocs.effectiveDate !== 'none'
                    ? moment(insuranceDocs.effectiveDate).format('YYYY-MM-DD')
                    : '-'}
                </p>
              )}
            </div>
            <div
              className={`c-agreement-box__date ${
                selectedAgreement.invalidSections?.includes(
                  AGREEMENT_SECTIONS.INSURANCE_DOC
                )
                  ? 'c-agreement-box__date--error'
                  : ''
              }`}
            >
              <label htmlFor="active">
                <Text tid="expires" />
              </label>
              {insuranceDocs.isApplicable ? (
                <div className="c-choose-date">
                  <DatePicker
                    placeholderText="choose date..."
                    className="c-date-picker"
                    dateFormat="yyyy/MM/dd"
                    disabled={!insuranceDocs.isApplicable}
                    selected={
                      insuranceDocs.expirationDate !== 'none'
                        ? new Date(insuranceDocs.expirationDate)
                        : null
                    }
                    customInput={
                      <MaskedInput
                        mask={dateRegexPattern}
                        keepCharPositions={true}
                        guide={true}
                      />
                    }
                    onChange={(date) => {
                      const newInsuranceDocs = { ...insuranceDocs };
                      newInsuranceDocs.expirationDate =
                        date === null ? 'none' : moment(date).format();
                      setInsuranceDocs(newInsuranceDocs);
                    }}
                  />
                </div>
              ) : (
                <p className="u-margin-top-small">
                  {insuranceDocs.expirationDate !== 'none'
                    ? moment(insuranceDocs.expirationDate).format('YYYY-MM-DD')
                    : '-'}
                </p>
              )}
            </div>
            <div className="l-flex-between c-agreement-box__doc-box">
              {insuranceDocs.isApplicable && !insuranceDocs.idDocExists ? (
                <UploadDoc
                  handleSetFile={({
                    documentName,
                    file,
                  }: {
                    documentName: string;
                    file: any;
                  }) => {
                    const newInsuranceDocs = { ...insuranceDocs };
                    newInsuranceDocs.document = {
                      documentName,
                      file,
                    };
                    newInsuranceDocs.idDocExists = true;
                    newInsuranceDocs.idDocName = documentName;
                    setInsuranceDocs(newInsuranceDocs);
                  }}
                />
              ) : insuranceDocs.idDocExists ? (
                <>
                  <div className="l-flex-between c-agreement-box__doc-box">
                    <button
                      className="c-btn-link-text c-agreement-box__doc-link"
                      type="button"
                      onClick={() => downloadDocument(DOC_TYPE.ID)}
                    >
                      {insuranceDocs.idDocName}
                    </button>
                  </div>
                  <div className="c-agreement-box__doc-link u-margin-top-large">
                    <button
                      className="c-btn-icon"
                      type="button"
                      // disabled={!isSite}
                      onClick={() => {
                        confirmDeleteDoc();
                      }}
                    >
                      <i className="fal fa-trash-alt c-agreement-box__delete-btn"></i>
                    </button>
                  </div>
                </>
              ) : null}
              <div className="l-flex-between c-agreement-box__doc-box">
                {insuranceDocs.isApplicable ? (
                  <LoaderButton
                    className={'c-btn-top-bottom-margins'}
                    type="button"
                    id="saveBtn"
                    disabled={insuranceDocs === selectedAgreement.insuranceDocs}
                    isLoading={isLoading}
                    text={TextOnly('update')}
                    loadingText={TextOnly('updating')}
                    onClick={() => {
                      saveInsuranceDocs();
                    }}
                  />
                ) : (
                  ''
                )}
              </div>
            </div>
          </div>
        </div>

        {/* ANNUAL FEES */}

        <div className="c-agreement-box">
          <div className="c-agreement-box__grid-layout-annual">
            <div className="c-agreement-box__align-top">
              <p className="c-agreement-box__title">
                <Text tid="annualFees" />
              </p>
              <div className="l-flex-start u-margin-top-small">
                <input
                  type="checkbox"
                  className="c-agreement-box__applicable-checkbox"
                  name="annualFees.isApplicable"
                  value={selectedAgreement.annualFees.isApplicable.toString()}
                  checked={selectedAgreement.annualFees.isApplicable}
                  readOnly
                />
                <p className="c-agreement-box__applicable-text">
                  <Text tid="applicable" />
                </p>
              </div>
            </div>

            <div className="c-agreement-box__date">
              <div>
                <label htmlFor="year">
                  <Text tid="modelYear" />
                </label>
                <p className="u-margin-top-small">
                  {formatModelYears(
                    selectedAgreement.annualFees.feeHistory?.[0].modelYear || ''
                  )}
                </p>
              </div>
            </div>

            <div
              className={`c-agreement-box__date ${
                selectedAgreement.invalidSections?.includes(
                  AGREEMENT_SECTIONS.ANNUAL_FEE
                )
                  ? 'c-agreement-box__date--error'
                  : ''
              }`}
            >
              <label htmlFor="active">
                <Text tid="invoiced" />
              </label>
              <p className="u-margin-top-small">
                {selectedAgreement.annualFees.feeHistory?.[0]?.invoiceDate !==
                'none'
                  ? moment(
                      selectedAgreement.annualFees.feeHistory?.[0]?.invoiceDate
                    ).format('YYYY-MM-DD')
                  : '-'}
              </p>
            </div>
            <div
              className={`c-agreement-box__date ${
                selectedAgreement.invalidSections?.includes(
                  AGREEMENT_SECTIONS.ANNUAL_FEE
                )
                  ? 'c-agreement-box__date--error'
                  : ''
              }`}
            >
              <label htmlFor="active">
                <Text tid="poRecResent" />
              </label>
              <p className="u-margin-top-small">
                {selectedAgreement.annualFees.feeHistory?.[0]?.receivedDate !==
                'none'
                  ? moment(
                      selectedAgreement.annualFees.feeHistory?.[0]?.receivedDate
                    ).format('YYYY-MM-DD')
                  : '-'}
              </p>
            </div>
            <div
              className={`c-agreement-box__date ${
                selectedAgreement.invalidSections?.includes(
                  AGREEMENT_SECTIONS.ANNUAL_FEE
                )
                  ? 'c-agreement-box__date--error'
                  : ''
              }`}
            >
              <label htmlFor="active">
                <Text tid="paidPending" />
              </label>
              <p className="u-margin-top-small">
                {selectedAgreement.annualFees.feeHistory?.[0]?.paidDate !==
                'none'
                  ? moment(
                      selectedAgreement.annualFees.feeHistory?.[0]?.paidDate
                    ).format('YYYY-MM-DD')
                  : '-'}
              </p>
            </div>
            <div
              className={`c-agreement-box__date ${
                selectedAgreement.invalidSections?.includes(
                  AGREEMENT_SECTIONS.ANNUAL_FEE
                )
                  ? 'c-agreement-box__date--error'
                  : ''
              }`}
            >
              <label htmlFor="active">
                <Text tid="paidToOem" />
              </label>
              <p className="u-margin-top-small">
                {selectedAgreement.annualFees.feeHistory?.[0]?.paidToOem !==
                'none'
                  ? moment(
                      selectedAgreement.annualFees.feeHistory?.[0]?.paidToOem
                    ).format('YYYY-MM-DD')
                  : '-'}
              </p>
            </div>
            <div className="l-flex-between c-agreement-box__doc-box">
              {selectedAgreement?.annualFees?.feeHistory?.[0].adDocExists ? (
                <div className="l-flex-between c-agreement-box__doc-box">
                  <button
                    className="c-btn-link-text c-agreement-box__doc-link"
                    type="button"
                    onClick={() =>
                      downloadDocument(
                        DOC_TYPE.AD,
                        selectedAgreement.annualFees.feeHistory?.[0].modelYear
                      )
                    }
                  >
                    {selectedAgreement.annualFees.feeHistory?.[0].adDocName}
                  </button>
                </div>
              ) : null}
            </div>
          </div>
          <div className="l-flex-between">
            <div />
            <button
              className="c-btn-link-text c-agreement-box__see-more"
              onClick={toggleAccordion}
              type="button"
            >
              <div className="c-btn__inner">
                {!showAccordion ? 'See More' : 'See Less'}
              </div>
            </button>
          </div>
          {showAccordion ? (
            <>
              {feeHistory.map((fee: FeeHistory, index: number) =>
                index !== 0 ? (
                  <div
                    className="c-agreement-box__annual-fees-grid-layout"
                    key={index}
                  >
                    <p>{formatModelYears(fee.modelYear)}</p>
                    <p>
                      {fee.invoiceDate !== 'none'
                        ? moment(fee.invoiceDate).format('YYYY-MM-DD')
                        : '-'}
                    </p>
                    <p>
                      {fee.receivedDate !== 'none'
                        ? moment(fee.receivedDate).format('YYYY-MM-DD')
                        : '-'}
                    </p>
                    <p>
                      {fee.paidDate !== 'none'
                        ? moment(fee.paidDate).format('YYYY-MM-DD')
                        : '-'}
                    </p>
                    <p>
                      {fee.paidToOem !== 'none'
                        ? moment(fee.paidToOem).format('YYYY-MM-DD')
                        : '-'}
                    </p>
                    <div className="l-flex-between c-agreement-box__doc-box">
                      <button
                        className="c-btn-link-text c-agreement-box__doc-link"
                        type="button"
                        onClick={() =>
                          downloadDocument(DOC_TYPE.AD, fee.modelYear)
                        }
                      >
                        {fee.adDocName}
                      </button>
                    </div>
                  </div>
                ) : null
              )}
            </>
          ) : null}
        </div>
      </div>
    </Dialog>
  );
};
