import { User } from '../types';
import { LangDictKey, TextOnly } from '../components/Text';
import { isMoment } from 'moment';

export function getAuditLogLangPhraseId(engString: string) {
  const phraseIdStringPairs: { [key: string]: string } = {
    'User created their account': 'userCreatedTheirAcc',
    'User confirmed their account': 'userConfirmedTheirAcc',
    'User imported a set of new users': 'userImportedNewUsers',
    'User performed a bulk invite for a set of users to join a shop':
      'userBulkInvitedUsers',
    'User revoked an invite for a user to join a shop': 'userRevokedInvite',
    'User updated their info': 'userUpdatedInfo',
    'User created a shop': 'userCreatedShop',
    "User updated a shop's info": 'userUpdatedShop',
    "User updated a shop's payment info": 'userUpdatedPaymentInfo',
    "User removed a shop's payment info": 'userRemovedPaymentInfo',
    "User updated a shop's max user capacity": 'userUpdatedMaxCap',
    'User added a user to a shop': 'userAddedUser',
    'User removed a user from a shop': 'userRemovedUser',
    'User responded to a shop invitation': 'userRespondedToInvite',
    'User changed a users state at a shop': 'userChangedUserState',
    'User registered a tool for a shop': 'userRegisteredTool',
    'User removed a tool from a shop': 'userRemovedTool',
    'User imported a set of tools into a shop': 'userImportedTools',
    'User logged in via a tool': 'userLoggedInTool',
    'User performed an ADInitiate via a tool': 'userADInitiateTool',
    'User performed an ADRequest via a tool': 'userADRequestTool',
    'User performed an ADExecute via a tool': 'userADExecuteTool',
    'User created a signup link for users to join shop':
      'userCreatedSignupLink',
    'User updated a signup link': 'userUpdatedSignupLink',
    'User got an error on ADInitiate via a tool': 'userErrorADInitiateTool',
    'User got an error on ADRequest via a tool': 'userErrorADRequestTool',
    'User got an error on ADExecute via a tool': 'userErrorADExecuteTool',
    'Shop subscription state was changed by an automated process':
      'shopSubscriptionStateChanged',
  };

  return TextOnly(phraseIdStringPairs[engString] as LangDictKey);
}

export function getSavedColumns(name: string): Record<string, number> {
  return JSON.parse(localStorage.getItem(name) || '[]').reduce(
    (acc: Record<string, string>, column: { id: string; value: number }) => ({
      ...acc,
      [column.id]: column.value,
    }),
    {}
  );
}

export function findUserById(
  userId: string,
  shopUsers: User[]
): User | undefined {
  return shopUsers.find((user) => user.userID === userId);
}

export function getUserName(userId: string, shopUsers: User[]): string {
  const user = findUserById(userId, shopUsers);
  if (user) {
    return `${user.firstName} ${user.lastName}`;
  }
  return '';
}

export function capitalize(word: string): string {
  if (word.length === 0) {
    return word;
  }
  const loweredCase = word.toLowerCase();
  return word[0].toUpperCase() + loweredCase.slice(1);
}

export function trimObjFields(obj: Record<string, any>): any {
  // If the value of this object is an Array, Date, or Moment, do not convert it
  // Additionally, trim array fields
  if (obj) {
    if (obj.constructor === Array) {
      let trimmedArray = obj as Array<any>;
      return trimmedArray.map((element) => {
        return trimObjFields(element);
      });
    } else if (
      obj.constructor === Date ||
      isMoment(obj) ||
      typeof obj === 'string'
    ) {
      return obj;
    }
  }

  let newObj = { ...obj };
  for (const key in newObj) {
    if (typeof newObj[key] === 'string') {
      newObj[key] = newObj[key].trim();
    }
    // recursive into objects
    if (typeof newObj[key] === 'object') {
      newObj[key] = trimObjFields(newObj[key]);
    }
  }
  return newObj;
}

export function fieldChangeCase(
  initValue: object,
  updateState: (val: object) => void,
  eventTarget: EventTarget & HTMLInputElement,
  targetCase: 'lower' | 'upper',
  whitespace: boolean = true
) {
  let field = document.getElementById(eventTarget.id) as HTMLInputElement;
  let cursorLocation = eventTarget.selectionStart;
  let currentValue = field.value;
  let newValue;
  if (targetCase === 'lower') {
    newValue = currentValue.toLowerCase();
  } else {
    newValue = currentValue.toUpperCase();
  }
  if (!whitespace) {
    const reg = new RegExp(/^(\S*)$/);
    if (reg.test(newValue) === true) {
      updateState({
        ...initValue,
        [eventTarget.id]: newValue,
      });
      field.value = newValue;
    }
  } else {
    updateState({
      ...initValue,
      [eventTarget.id]: newValue,
    });
    field.value = newValue;
  }

  field.setSelectionRange(cursorLocation, cursorLocation);
}

interface LoginTokens {
  IdToken: string;
  ExpiresIn: number;
  RefreshToken: string;
}

export const authStorage = {
  getLoginTokens() {
    const tokens = localStorage.getItem('tokens') || '{}';
    return JSON.parse(tokens) as LoginTokens;
  },
  setLoginTokens(loginTokens: LoginTokens) {
    localStorage.setItem('tokens', JSON.stringify(loginTokens));
  },
  removeLoginTokens() {
    localStorage.removeItem('tokens');
  },
  async setItem(key: string, value: string) {
    localStorage.setItem(key, value);
  },
  async getItem(key: string) {
    let findKey = key;
    const tokens = localStorage.getItem('tokens');
    if (key.includes('LastAuthUser')) {
      findKey = 'LastAuthUser';
    } else if (key.includes('idToken')) {
      findKey = 'IdToken';
    } else if (key.includes('accessToken')) {
      findKey = 'AccessToken';
    }
    return tokens ? JSON.parse(tokens)[findKey] : '';
  },
  async removeItem(key: string) {},
  async clear() {},
  // isAboutToExpire
  // shouldRefreshToken
};

export const abbreviateNumber = (num: number) => {
  let abbreviations = ['', 'k', 'm', 'b', 't'];
  let abbreviatedNumber = num;
  let abbreviationIndex = 0;

  while (
    abbreviatedNumber >= 1000 &&
    abbreviationIndex < abbreviations.length - 1
  ) {
    abbreviatedNumber /= 1000;
    abbreviationIndex++;
  }

  // Round to 0 decimal place
  abbreviatedNumber = Math.round(abbreviatedNumber);

  // Add the abbreviation to the number
  return abbreviatedNumber + abbreviations[abbreviationIndex];
};

export const formatModelYears = (modelYears: string) => {
  const modelYearsArray = modelYears.replace(/\s+/g, '').split(',').sort();

  if (modelYearsArray.length === 2) {
    return `${modelYearsArray[0]}, ${modelYearsArray[1]}`;
  }

  let formattedYearsString = modelYearsArray[0];
  const modelYearsLen = modelYearsArray.length;
  let prevNumber = modelYearsArray[0];
  let yearCap = '';

  for (var i = 1; i < modelYearsLen; i++) {
    if (
      parseInt(modelYearsArray[i]) === parseInt(prevNumber) + 1 &&
      formattedYearsString.slice(-1) !== '-'
    ) {
      formattedYearsString += '-';
      yearCap = modelYearsArray[i];
    } else if (
      parseInt(modelYearsArray[i]) === parseInt(prevNumber) + 1 &&
      i === modelYearsLen - 1
    ) {
      formattedYearsString += modelYearsArray[i];
    } else if (parseInt(modelYearsArray[i]) === parseInt(prevNumber) + 1) {
      yearCap = modelYearsArray[i];
    } else {
      formattedYearsString += yearCap;
      formattedYearsString += ', ' + modelYearsArray[i];
    }

    prevNumber = modelYearsArray[i];
  }

  return formattedYearsString;
};
