import React, { useCallback, useEffect, useState } from 'react';
import { User } from '../types';
import { USER_TYPE } from '../CONSTANTS';
import { getUserInfo } from '../libs/db-lib';
import { authStorage } from '../libs/utils-ts';
import { signIn } from 'aws-amplify/auth';

export interface UserContextInterface {
  user: User | null;
  error: { error: string } | null;
  updateUser: (user: User | null) => void;
  fetchUser: () => void;
  fetchUserAndAuth: (username: string, password: string) => void;
  clearError: () => void;
  updateError: (error: string) => void;
  isAuthenticated: boolean;
  isAdmin: boolean;
  isSite: boolean;
  isOEM: boolean;
  isTool: boolean;
  vendorID: string;
}

// create the language context with default selected language
export const UserContext = React.createContext<UserContextInterface>({
  user: null,
  error: null,
  updateUser: (user: User | null) => null,
  fetchUser: () => null,
  fetchUserAndAuth: () => null,
  clearError: () => null,
  updateError: (error: string) => null,
  isAuthenticated: false,
  isAdmin: false,
  isSite: false,
  isOEM: false,
  isTool: false,
  vendorID: '',
});

export function useUser() {
  return React.useContext(UserContext);
}

type UserProviderType = {
  children: JSX.Element;
};

export function UserProvider({ children }: UserProviderType) {
  const [user, setUser] = useState<User | null>(null);
  const [error, setError] = useState<{
    error: string;
  } | null>(null);

  const updateUser = (newUser: User | null) => {
    setUser(newUser);
  };

  const fetchUser = useCallback(async () => {
    const response = await getUserInfo().catch((err) => {
      setError(err);
      return null;
    });

    if (response?.error) {
      setError(response);
    } else {
      updateUser(response || null);
    }
  }, [error, updateUser, clearError]);

  const fetchUserAndAuth = useCallback(
    async (username: string, password: string) => {
      const response = await getUserInfo().then((response) => {
        if (response?.authProvider === 'COGNITO') {
          const partnerPortalSignIn = async () => {
            await signIn({ username, password });
          };
          partnerPortalSignIn();
        }
        return response;
      });

      if (response?.error) {
        setError(response);
      } else {
        updateUser(response || null);
      }
    },
    [error, updateUser, clearError]
  );

  useEffect(() => {
    const IdToken = authStorage.getLoginTokens()?.IdToken;
    if (!user && IdToken && !error) {
      fetchUser();
    }
  }, [user, fetchUser]);

  function clearError() {
    setError(null);
  }

  function updateError(error: string) {
    setError({ error });
  }

  const handleLogout = async () => {
    authStorage.removeLoginTokens();
    updateUser(null);
    clearError();
  };

  const isAdmin =
    user?.userType === USER_TYPE.SITE_ADMIN ||
    user?.userType === USER_TYPE.OEM_ADMIN ||
    user?.userType === USER_TYPE.TOOL_ADMIN;
  const isSite = user?.userType === USER_TYPE.SITE_ADMIN || false;
  const isOEM = (user && /OEM/.test(user.userType)) || false;
  const isTool = (user && /TOOL/.test(user.userType)) || false;

  const provider = {
    user,
    error,
    clearError,
    updateError,
    updateUser,
    fetchUser,
    fetchUserAndAuth,
    isAdmin,
    isSite,
    isOEM,
    isTool,
    handleLogout,
    isAuthenticated: !!user,
    vendorID: user?.vendorID || '',
  };

  return (
    <UserContext.Provider value={provider}>{children}</UserContext.Provider>
  );
}
