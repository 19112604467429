import {
  fetchAuthSession,
  updatePassword,
  confirmUserAttribute,
  sendUserAttributeVerificationCode,
} from 'aws-amplify/auth';
import { useFormik } from 'formik';
import React from 'react';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { Header } from '../components/Header';
import LoaderButton from '../components/LoaderButton';
import { TextOnly, Text } from '../components/Text';
import { useUser } from '../context/User';
import { updateUser, UpdateUserParams } from '../libs/db-lib';
import { nameValidate, isValidEmail, passwordValidate } from '../libs/utils';

export const UserProfile = () => {
  const { user, fetchUser } = useUser();

  // react mutation for update user
  const updateUserMutation = useMutation<
    { error?: string },
    Error,
    UpdateUserParams
  >((userFields) => updateUser(userFields), {
    onSuccess: (data) => {
      if (data?.error) {
        toast.error(data.error, {
          autoClose: false,
          containerId: 'standard',
        });
        return;
      }
      toast.success(TextOnly('updateSuccess'), {
        containerId: 'standard',
      });
      formik.setSubmitting(false);
      emailFormik.setSubmitting(false);
      fetchUser();
    },
    onError: (error) => {
      toast.error(error.message, {
        autoClose: false,
        containerId: 'standard',
      });
    },
  });

  const handlePasswordChangeSubmit = async (
    oldPassword: string,
    newPassword: string
  ) => {
    try {
      await updatePassword({ oldPassword, newPassword });
      passwordFormik.resetForm();
      toast.success(TextOnly('passwordUpdated'), {
        containerId: 'standard',
      });
    } catch (error: any) {
      toast.error(error.message, {
        autoClose: false,
        containerId: 'standard',
      });
    }
  };

  const formik = useFormik({
    initialValues: {
      firstName: user?.firstName || '',
      lastName: user?.lastName || '',
    },
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true);
      updateUserMutation.mutate({
        firstName: values.firstName,
        lastName: values.lastName,
        email: user?.email || '',
      });
    },
  });

  const emailFormik = useFormik({
    initialValues: {
      email: user?.email || '',
    },
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true);
      updateUserMutation.mutate(
        {
          firstName: user?.firstName || '',
          lastName: user?.lastName || '',
          email: values.email,
        },
        {
          onSettled: () => {
            setSubmitting(false);
          },
        }
      );
    },
  });

  const verifyEmailFormik = useFormik({
    initialValues: {
      confirmCode: '',
    },
    onSubmit: (values, { setSubmitting, resetForm }) => {
      setSubmitting(true);
      confirmUserAttribute({
        userAttributeKey: 'email',
        confirmationCode: values.confirmCode,
      })
        .then(() => {
          toast.success(TextOnly('accountValidationSuccess'), {
            containerId: 'standard',
          });
          fetchUser();
          resetForm();
          setSubmitting(false);
        })
        .catch((error) => {
          toast.error(error.message, {
            autoClose: false,
            containerId: 'standard',
          });
          setSubmitting(false);
        });
    },
  });

  const passwordFormik = useFormik({
    initialValues: {
      password: '',
      newPassword: '',
      confirmPassword: '',
    },
    onSubmit: async (values) => {
      if (values.newPassword !== values.confirmPassword) {
        toast.error(TextOnly('passwordMismatch'), {
          containerId: 'standard',
        });
        return;
      }
      await handlePasswordChangeSubmit(values.password, values.newPassword);
    },
  });

  function handleResendCode() {
    sendUserAttributeVerificationCode({ userAttributeKey: 'email' })
      .then(() => {
        toast.success(TextOnly('codeSent'), {
          containerId: 'standard',
        });
        fetchUser();
      })
      .catch((error) => {
        toast.error(error.message, {
          autoClose: false,
          containerId: 'standard',
        });
      });
  }

  return (
    <>
      <Header title={TextOnly('userProfile')} />
      <div className="l-view-layout--user-profile">
        <div className="user-profile-form-column">
          <form onSubmit={formik.handleSubmit}>
            <div>
              <h2>
                <Text tid="userDetails" />
              </h2>
              <label className="u-margin-bottom-large">
                <Text tid="usernameLabel" />: {user?.userName}
              </label>
            </div>
            <div className="c-field">
              <label htmlFor="firstName" className="c-field__label">
                <Text tid="firstName" />:
              </label>
              <input
                id="firstName"
                name="firstName"
                type="text"
                maxLength={50}
                className={`c-input ${
                  !nameValidate(formik.values.firstName) ? 'error' : ''
                }`}
                placeholder={TextOnly('firstName')}
                value={formik.values.firstName}
                onChange={formik.handleChange}
              />
              {formik.errors.firstName ? (
                <div className="c-field__error">
                  <Text tid="firstNameHint" />
                </div>
              ) : null}
            </div>

            <div className="c-field">
              <label htmlFor="lastName" className="c-field__label">
                <Text tid="lastName" />:
              </label>
              <input
                id="lastName"
                type="text"
                maxLength={50}
                className={`c-input ${
                  !nameValidate(formik.values.lastName) ? 'error' : ''
                }`}
                placeholder={TextOnly('lastName')}
                value={formik.values.lastName}
                onChange={formik.handleChange}
              />
              {formik.errors.lastName ? (
                <div className="c-field__error">
                  <Text tid="lastNameHint" />
                </div>
              ) : null}
            </div>

            <LoaderButton
              type="submit"
              id="submit-button"
              disabled={!formik.isValid}
              isLoading={formik.isSubmitting}
              text={TextOnly('update')}
              loadingText={TextOnly('updating')}
            />
          </form>
        </div>
        <div className="user-profile-form-column">
          <form onSubmit={emailFormik.handleSubmit}>
            <div>
              <h2>
                <Text tid="changeEmail" />
              </h2>
            </div>
            <div className="c-field">
              <label htmlFor="email" className="c-field__label">
                <Text tid="email" />:
              </label>
              <input
                id="email"
                name="email"
                type="email"
                maxLength={50}
                className={`c-input ${
                  !isValidEmail(emailFormik.values.email) ? 'error' : ''
                }`}
                placeholder={TextOnly('email')}
                value={emailFormik.values.email}
                onChange={emailFormik.handleChange}
                disabled={!user?.emailVerified}
              />
              {emailFormik.errors.email ? (
                <div className="c-field__error">
                  <Text tid="emailHint" />
                </div>
              ) : null}
            </div>
            {user?.emailVerified ? (
              <LoaderButton
                type="submit"
                id="submit-button"
                disabled={
                  !emailFormik.isValid ||
                  user.email === emailFormik.values.email
                }
                isLoading={emailFormik.isSubmitting}
                text={TextOnly('update')}
                loadingText={TextOnly('updating')}
              />
            ) : null}
          </form>

          {!user?.emailVerified ? (
            <form onSubmit={verifyEmailFormik.handleSubmit}>
              <div className="c-field">
                <p className="u-margin-bottom-large">
                  <Text tid="accountValidationCodeSent" />
                </p>
                <label htmlFor="confirmCode" className="c-field__label">
                  <Text tid="confirmationCode" />:
                </label>
                <input
                  id="confirmCode"
                  name="confirmCode"
                  type="text"
                  maxLength={50}
                  className={`c-input ${
                    !isValidEmail(verifyEmailFormik.values.confirmCode)
                      ? 'error'
                      : ''
                  }`}
                  placeholder={TextOnly('confirmationCode')}
                  value={verifyEmailFormik.values.confirmCode}
                  onChange={verifyEmailFormik.handleChange}
                />
              </div>
              <LoaderButton
                type="submit"
                id="submit-button"
                disabled={!verifyEmailFormik.isValid}
                isLoading={verifyEmailFormik.isSubmitting}
                text={TextOnly('submitConfirmationCode')}
                loadingText={TextOnly('confirming')}
              />

              <button
                type="button"
                className="c-btn-link-bare u-margin-top-small"
                onClick={handleResendCode}
              >
                <div className="c-btn__inner">
                  {TextOnly('resendConfirmCode')}
                </div>
              </button>
            </form>
          ) : null}
        </div>
        <div className="user-profile-form-column">
          <form onSubmit={passwordFormik.handleSubmit}>
            <div>
              <h2>
                <Text tid="changePassword" />
              </h2>
            </div>
            <div className="c-field">
              <label htmlFor="password" className="c-field__label">
                <Text tid="password" />:
              </label>
              <input
                id="password"
                name="password"
                type="password"
                maxLength={50}
                className={`c-input ${
                  !passwordValidate(passwordFormik.values.password)
                    ? 'error'
                    : ''
                }`}
                placeholder={TextOnly('password')}
                value={passwordFormik.values.password}
                onChange={passwordFormik.handleChange}
              />
              {passwordFormik.errors.password ? (
                <div className="c-field__error">
                  <Text tid="passwordHint" />
                </div>
              ) : null}
            </div>
            <div className="c-field">
              <label htmlFor="newPassword" className="c-field__label">
                <Text tid="newPassword" />:
              </label>
              <input
                id="newPassword"
                name="newPassword"
                type="password"
                maxLength={50}
                className={`c-input ${
                  !passwordValidate(passwordFormik.values.newPassword)
                    ? 'error'
                    : ''
                }`}
                placeholder={TextOnly('newPassword')}
                value={passwordFormik.values.newPassword}
                onChange={passwordFormik.handleChange}
              />
              {passwordFormik.errors.newPassword ? (
                <div className="c-field__error">
                  <Text tid="newPasswordHint" />
                </div>
              ) : null}
            </div>
            <div className="c-field">
              <label htmlFor="confirmPassword" className="c-field__label">
                <Text tid="confirmPassword" />:
              </label>
              <input
                id="confirmPassword"
                name="confirmPassword"
                type="password"
                maxLength={50}
                className={`c-input ${
                  !passwordValidate(passwordFormik.values.confirmPassword)
                    ? 'error'
                    : ''
                }`}
                placeholder={TextOnly('confirmPassword')}
                value={passwordFormik.values.confirmPassword}
                onChange={passwordFormik.handleChange}
              />
              {passwordFormik.errors.confirmPassword ? (
                <div className="c-field__error">
                  <Text tid="confirmPasswordHint" />
                </div>
              ) : null}
            </div>
            <LoaderButton
              type="submit"
              id="submit-button"
              disabled={!passwordFormik.isValid}
              isLoading={passwordFormik.isSubmitting}
              text={TextOnly('changePassword')}
              loadingText={TextOnly('updating')}
            />
          </form>

          {/*
        <div className="c-field">
          <label htmlFor="email" className="c-field__label">
            <Text tid="emailAddress" />:
          </label>
          <input
            id="email"
            type="text"
            maxLength="50"
            className={`c-input ${
              !isValidEmail(this.state.email) ? 'error' : ''
            }`}
            placeholder={TextOnly('email')}
            value={this.state.email}
            onChange={this.handleChange}
          />
        </div>

        <div className="u-margin-bottom">
          {this.state.emailVerified ||
          this.state.emailChanged ||
          this.state.emailVerificationCodeSent ? null : (
            <button
              type="button"
              id="validate-email"
              className="c-btn"
              disabled={
                this.state.emailVerificationCodeSent ||
                this.state.emailChanged
              }
              onClick={this.handleValidateEmail}
            >
              <Text tid="validateEmail" />
            </button>
          )}
        </div>
        {this.state.emailVerificationCodeSent &&
        !this.state.emailChanged ? (
          <>
            <div className="c-field">
              <label
                className="c-field__label"
                htmlFor="emailVerification"
              >
                <Text tid="emailSentMsg" />
              </label>
              <input
                type="text"
                id="confirmationCode"
                maxLength="10"
                value={this.state.confirmationCode}
                placeholder={TextOnly('confirmationCode')}
                onChange={this.handleChange}
              />
            </div>
            {this.state.emailVerified ? null : (
              <button
                type="button"
                id="validate-email"
                className="c-btn u-margin-bottom"
                disabled={
                  !this.state.emailVerificationCodeSent ||
                  this.state.confirmationCode?.length < 6
                }
                onClick={this.handleSubmitConfirmationCode}
              >
                <Text tid="submitConfirmationCode" />
              </button>
            )}
          </>
        ) : null}
        <div className="c-field">
          <label htmlFor="language" className="c-field__label">
            <Text tid="language" />:
          </label>
          <div className="c-select">
            <select
              id="language"
              onChange={this.handleChange.bind(this, 'language')}
              value={this.state.language}
            >
              {langOptions}
            </select>
          </div>
        </div> */}

          {/* {this.state.user && this.state.emailVerified === true ? (
        <form onSubmit={this.handlePasswordSubmit}>
          <h2>
            <Text tid="changePassword" />
          </h2>
          <div className="c-field">
            <label htmlFor="oldPassword" className="c-field__label">
              <Text tid="oldPassword" />:
            </label>
            <input
              id="oldPassword"
              type="password"
              maxLength="50"
              placeholder={TextOnly('oldPassword')}
              value={this.state.oldPassword}
              onChange={this.handleChange}
            />
          </div>

          <div className="c-field">
            <label htmlFor="newPassword" className="c-field__label">
              <Text tid="newPassword" />:
            </label>
            <input
              id="newPassword"
              type="password"
              maxLength="50"
              className={`c-input ${
                this.state.newPassword.length > 0 &&
                !passwordValidate(this.state.newPassword)
                  ? 'error'
                  : ''
              }`}
              placeholder={TextOnly('newPassword')}
              value={this.state.newPassword}
              onChange={this.handleChange}
            />
            {this.state.newPassword.length > 0 &&
            !passwordValidate(this.state.newPassword) ? (
              <div className="c-field__error">
                <Text tid="passwordHint" />
              </div>
            ) : null}
          </div>

          <div className="c-field">
            <label htmlFor="verifyPassword" className="c-field__label">
              <Text tid="verifyNewPassword" />:
            </label>
            <input
              id="verifyPassword"
              type="password"
              maxLength="50"
              className={`c-input ${
                this.state.verifyPassword.length > 0 &&
                this.state.newPassword !== this.state.verifyPassword
                  ? 'error'
                  : ''
              }`}
              placeholder={TextOnly('verifyNewPassword')}
              value={this.state.verifyPassword}
              onChange={this.handleChange}
            />
            {this.state.verifyPassword.length > 0 &&
            this.state.newPassword !== this.state.verifyPassword ? (
              <div className="c-field__error">
                <Text tid="confirmPasswordHint" />
              </div>
            ) : null}
          </div>

          <LoaderButton
            type="submit"
            disabled={!this.validatePasswordForm()}
            isLoading={this.state.isChangePasswordLoading}
            text={TextOnly('changePassword')}
            loadingText={TextOnly('changing')}
          />
        </form>
      ) : null} */}
        </div>
      </div>
      {/* <AlertModal
    title={this.state.alertTitle}
    message={this.state.alertMessage}
    showModal={this.state.showModal}
    size={'small'}
    handleCancel={this.handleCancel.bind(this)}
  /> */}
    </>
  );
};
