import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import { Amplify } from 'aws-amplify';
import { cognitoUserPoolsTokenProvider } from 'aws-amplify/auth/cognito';
import { BrowserRouter as Router } from 'react-router-dom';
// import 'react-table/react-table.css';
import '@reach/listbox/styles.css';
import '@reach/dialog/styles.css';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import 'react-toastify/dist/ReactToastify.css';
import './index.scss';
import App from './App';
import { ToastContainer } from 'react-toastify';
import registerServiceWorker from './registerServiceWorker';
import config from './config';
import { createBrowserHistory } from 'history';

import { LanguageProvider } from './libs/Language';
import { MenuProvider } from './context/Menu';
import { UserProvider } from './context/User';
import { ConfirmProvider } from './context/Confirm';
import { QueryWrapper } from './QueryWrapper';
import { MapProvider } from './context/Map';
import { authStorage } from './libs/utils-ts';

const history = createBrowserHistory();

Amplify.configure({
  Auth: {
    Cognito: {
      mandatorySignIn: true,
      region: config.cognito.REGION,
      userPoolId: config.cognito.USER_POOL_ID,
      identityPoolId: config.cognito.IDENTITY_POOL_ID,
      userPoolClientId: config.cognito.APP_CLIENT_ID,
    },
  },
  API: {
    REST: [
      {
        'echo-dev': {
          endpoint: config.apiGateway.URL,
          region: config.apiGateway.REGION,
        },
      },
      {
        'authdiag-dev': {
          endpoint: config.apiGateway.URL,
          region: config.apiGateway.REGION,
        },
      },
    ],
  },
});

cognitoUserPoolsTokenProvider.setKeyValueStorage(authStorage);

ReactDOM.render(
  <>
    <link
      href="https://api.mapbox.com/mapbox-gl-js/v2.8.2/mapbox-gl.css"
      rel="stylesheet"
    />
    <LanguageProvider>
      <UserProvider>
        <Router history={history}>
          <MenuProvider>
            <MapProvider>
              <ConfirmProvider>
                <QueryWrapper>
                  <App />
                </QueryWrapper>
              </ConfirmProvider>
            </MapProvider>
          </MenuProvider>
        </Router>
      </UserProvider>
    </LanguageProvider>
    <ToastContainer
      enableMultiContainer
      containerId={'standard'}
      autoClose={3500}
      closeOnClick
      pauseOnFocusLoss
      draggable
      pauseOnHover
      style={{
        zIndex: 10000,
      }}
    />
    <ToastContainer
      className={'wide-toast'}
      enableMultiContainer
      containerId={'wide'}
      autoClose={3500}
      closeOnClick
      pauseOnFocusLoss
      draggable
      pauseOnHover
      style={{
        zIndex: 10000,
      }}
    />
  </>,
  document.getElementById('root')
);
registerServiceWorker();
